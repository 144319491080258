import React, { useState, useRef, useEffect } from 'react';
import { Formik } from 'formik';
import ReactQuill, { Quill } from 'react-quill';
import { Modal } from 'components';
import Label from 'components/Forms/Label';
import ModalHeader from 'components/ModalHeader';
import DeficiencyList from '../pitchTemplates/DeficiencyList';
import moment from 'moment';
import { RefreshIcon } from '@heroicons/react/outline';
import SelectFilter from '../pitchTemplates/SelectFilter';

const CreatePitchTemplate = ({
  open,
  toggleModal,
  as = null,
  submitHandler,
  data,
  editMode,
}) => {
  const [deficiencyItems, setDeficiencyItems] = useState([
    { id: 1, title: 'Incomplete Product Information' },
    { id: 2, title: 'Low-Quality Images' },
    { id: 3, title: 'Keyword Stuffing or Irrelevant Keywords' },
    { id: 4, title: 'Pricing and Availability Issues' },
    { id: 5, title: 'Misleading Claims or False Information' },
    { id: 6, title: "Voilation of Amazon's Restricted Categories" },
  ]);
  const [textEditorIndex, setTextEditorIndex] = useState(-1);
  const [initialValue, setInitialValue] = useState({
    key: '',
    value: '',
    deficiencies: [],
  });
  const reloadValue = (e, setFieldValue) => {
    e.preventDefault();
    setFieldValue('value', data.value);
  };
  const quillRef = useRef();

  const resetDeficiencyItems = () => {
    setDeficiencyItems(deficiencyItems);
  };

  useEffect(() => {
    if (data) setInitialValue(data);
  }, [data]);

  const customBoldHandler = (e) => {
    e.preventDefault();
    const quill = quillRef.current.getEditor();
    if (quill && quill.getSelection() && quill.getSelection().length > 0) {
      const start = quill.getSelection().index;
      const end = quill.getSelection().index + quill.getSelection().length;

      const text = '-b-';

      quill.insertText(end, text);
      quill.insertText(start, text);
    }
  };

  const onBlurEditor = (e) => {
    e.preventDefault();

    const quill = quillRef.current.getEditor();
    if (quill && quill.getSelection()) {
      const index = quill.getSelection().index;
      setTextEditorIndex(index);
    }
  };

  const handleInsert = (val) => {
    const quill = quillRef.current.getEditor();
    if (textEditorIndex > 0) {
      const text = `--${val.value}--`;

      quill.insertText(textEditorIndex, text);
    }
  };

  return (
    <Modal
      open={open}
      setOpen={toggleModal}
      as={'div'}
      noOverlayClick={true}
      persistent={true}
      isBlock
    >
      <div className="bg-grayscale-300 my-8 p-8 text-left transition-all transform shadow-xl rounded-xl space-y-8">
        <ModalHeader
          title={
            <>
              <div className="flex items-center space-x-4 font-light justify-between">
                <span className="text-grayscale-700">Template Creation</span>
              </div>
            </>
          }
          setOpen={() => {
            toggleModal();
            setInitialValue({
              key: '',
              value: '',
              deficiencies: [],
            });
          }}
          titleClasses="capitalize"
          fontSize="text-xl"
          headingStyle
          fontStyle="font-regular"
          px="px-0"
          py="py-0"
        />{' '}
        <Formik
          initialValues={initialValue}
          enableReinitialize={true}
          onSubmit={(values, { setSubmitting }) => {
            submitHandler(values, setSubmitting);
          }}
          render={({
            values,
            setFieldValue,
            handleChange,
            handleSubmit,
            isSubmitting,
          }) => (
            <form onSubmit={handleSubmit}>
              <div className="container mx-auto">
                <div className="flex py-4 justify-between">
                  <div className="flex-row">
                    <Label for="key" className="mr-2">
                      Template Name
                    </Label>
                    <input
                      type="text"
                      name="key"
                      value={values.key}
                      onChange={handleChange}
                      className="rounded-xl border-none ml-2 pr-20 text-gray-900 bg-white ring-gray-300 sm:text-sm sm:leading-6"
                    />
                  </div>
                  <DeficiencyList
                    values={values}
                    deficiencyItems={deficiencyItems}
                    resetHandler={resetDeficiencyItems}
                  />
                </div>
              </div>
              <div className="grid grid-cols-1 gap-4">
                <div
                  className={`grid grid-cols-12 bg-white  py-2 shadow hover:shadow-xl text-left text-lg tracking-tight`}
                >
                  <div className="col-span-4">
                    <div className="grid grid-cols-8 gap-4 pt-2">
                      <button
                        className="pl-4"
                        onClick={(e) => reloadValue(e, setFieldValue)}
                      >
                        <RefreshIcon className="h-5 w-5" color="gray" />
                      </button>
                      <button onClick={(e) => customBoldHandler(e)}>𝗕</button>
                    </div>
                  </div>
                  <div className="col-start-8 col-end-12">
                    <SelectFilter handleInsert={handleInsert} />
                  </div>
                </div>

                <div onBlur={(e) => onBlurEditor(e)}>
                  <ReactQuill
                    ref={quillRef}
                    onChange={(value) => setFieldValue('value', value)}
                    theme="snow"
                    style={{ marginBottom: '2rem' }}
                    value={values.value}
                    modules={{
                      toolbar: false,
                    }}
                  />
                </div>
              </div>

              <div className="flex flex-row justify-between  items-center mt-5">
                <p className="text-13 text-gray-500">
                  {editMode
                    ? 'Last edited ' +
                      moment(data.updatedAt).format('MMMM Do h:mm:ss a')
                    : ''}
                </p>
                <div>
                  <button
                    type="submit"
                    disabled={isSubmitting}
                    className="bg-secondary hover:bg-blue-400 text-white py-2 px-4 rounded-full w-44"
                  >
                    {editMode ? 'Edit Template' : 'Save Template'}
                  </button>
                </div>
              </div>
            </form>
          )}
        />
      </div>
    </Modal>
  );
};

export default CreatePitchTemplate;
