import { useState } from 'react';
import DayPicker, { DateUtils } from 'react-day-picker';
import 'react-day-picker/lib/style.css';
import { CalendarIcon, XIcon } from '@heroicons/react/outline';
import { DateTime } from 'luxon';

const DateRangePickerNew = ({ onChangeDates }) => {
  const [from, setFrom] = useState(null);
  const [to, setTo] = useState(null);
  const [enteredTo, setEnteredTo] = useState(null);

  const isSelectingFirstDay = (from, to, day) => {
    const isBeforeFirstDay = from && DateUtils.isDayBefore(day, from);
    const isRangeSelected = from && to;
    return !from || isBeforeFirstDay || isRangeSelected;
  };

  const handleDayClick = (day) => {
    if (from && to && day >= from && day <= to) {
      handleResetClick();
      return;
    }
    if (isSelectingFirstDay(from, to, day)) {
      setFrom(day);
      setTo(null);
      setEnteredTo(null);
    } else {
      setTo(day);
      setEnteredTo(day);
      onChangeDates(from, day);
    }
  };

  const handleDayMouseEnter = (day) => {
    if (isSelectingFirstDay(from, to, day)) return;

    setEnteredTo(day);
  };

  const handleResetClick = () => {
    setFrom(null);
    setTo(null);
    setEnteredTo(null);
    onChangeDates(null, null);
  };

  const modifiers = { start: from, end: enteredTo };
  const disabledDays = { before: from };
  const selectedDays = [from, { from, to: enteredTo }];

  return (
    <div>
      <div className="flex items-center px-5 mb-2">
        <div className="flex justify-between border rounded-md px-3 py-1.5 w-full">
          <div className="flex space-x-3 text-grayscale-700">
            {!from && !to && <span>Select date range</span>}
            {from && (
              <span>{DateTime.fromJSDate(from).toFormat('LLLL dd, yyyy')}</span>
            )}
            {to && (
              <>
                <span>&mdash;</span>
                <span>{DateTime.fromJSDate(to).toFormat('LLLL dd, yyyy')}</span>
              </>
            )}
          </div>

          {from && to ? (
            <button className="link" onClick={handleResetClick}>
              <XIcon
                className="h-5 w-5 hover:text-gray-500 text-gray-600"
                aria-hidden="true"
              />
            </button>
          ) : (
            <CalendarIcon
              className="h-5 w-5 text-gray-400"
              aria-hidden="true"
            />
          )}
        </div>
      </div>
      <DayPicker
        className="Range"
        numberOfMonths={2}
        fromMonth={from}
        selectedDays={selectedDays}
        disabledDays={disabledDays}
        modifiers={modifiers}
        onDayClick={handleDayClick}
        onDayMouseEnter={handleDayMouseEnter}
      />
    </div>
  );
};

export default DateRangePickerNew;
