import { useDispatch, useSelector } from 'react-redux';
import {
  setData,
  setDefaultSignature,
  setDraftMail,
  setMailSubject,
} from './mailSlice';
import { useHistory } from 'react-router-dom';
import { Quill } from 'react-quill';
import Delta from 'quill-delta';
import { ImageActions } from '@xeger/quill-image-actions';
import { ImageFormats } from '@xeger/quill-image-formats';
import { useCallback, useState } from 'react';
import { setAlert } from 'features/alerts/alertsSlice';
import { has, isEqual } from 'lodash';
import QuillImageDropAndPaste from 'quill-image-drop-and-paste'

Quill.register('modules/imageDropAndPaste', QuillImageDropAndPaste)
Quill.register('modules/imageActions', ImageActions);
Quill.register('modules/imageFormats', ImageFormats);

//BEGIN allow image alignment styles
const ImageFormatAttributesList = ['alt', 'height', 'width', 'style', 'align'];

const BaseImageFormat = Quill.import('formats/image');
class ImageFormat extends BaseImageFormat {
  static formats(domNode) {
    return ImageFormatAttributesList.reduce(function (formats, attribute) {
      if (domNode.hasAttribute(attribute)) {
        formats[attribute] = domNode.getAttribute(attribute);
      }
      return formats;
    }, {});
  }
  format(name, value) {
    if (ImageFormatAttributesList.indexOf(name) > -1) {
      if (value) {
        this.domNode.setAttribute(name, value);
      } else {
        this.domNode.removeAttribute(name);
      }
    } else {
      super.format(name, value);
    }
  }
}

Quill.register(ImageFormat, true);
//END allow image alignment styles

const ATTRIBUTES = ['src', 'title', 'class', 'width', 'height', 'style'];
const Embed = Quill.import('blots/embed');
const BlockEmbed = Quill.import('blots/block/embed');
class InlineImageBlot extends BlockEmbed {
  static create(val) {
    const { value, props } = val;

    const node = super.create(value);
    if (typeof value === 'string') {
      node.setAttribute('src', value);
    }
    node.setAttribute('title', props);
    node.setAttribute('class', 'inline-img');
    return node;
  }
  static formats(domNode) {
    return ATTRIBUTES.reduce((formats, attribute) => {
      if (domNode.hasAttribute(attribute)) {
        formats[attribute] = domNode.getAttribute(attribute);
      }
      return formats;
    }, {});
  }
  static value(domNode) {
    return {
      src: domNode.getAttribute('src'),
      title: domNode.getAttribute('title'),
    };
  }
  format(name, value) {
    if (ATTRIBUTES.indexOf(name) > -1) {
      if (value) {
        this.domNode.setAttribute(name, value);
      } else {
        this.domNode.removeAttribute(name);
      }
    } else {
      super.format(name, value);
    }
  }
}
InlineImageBlot.blotName = 'inlineImage';
InlineImageBlot.tagName = 'IMG';
InlineImageBlot.className = 'inline-img';

Quill.register(InlineImageBlot);

class MailSignatureBlot extends Embed {
  static create(value) {
    let node = super.create();
    const { html, key, type } = value;

    node.setAttribute('data-id', key);
    node.setAttribute('data-type', type);
    node.innerHTML = html;

    return node;
  }

  static value(node) {
    return {
      html: node.innerHTML,
      key: node.getAttribute('data-id'),
      type: node.getAttribute('data-type'),
    };
  }
}
MailSignatureBlot.blotName = 'mailSignature';
MailSignatureBlot.tagName = 'DIV';
MailSignatureBlot.className = 'mail-signature';

Quill.register(MailSignatureBlot);

const useMail = (qRef = null) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [value, setValue] = useState('');
  const [contentValue, setContentValue] = useState('');
  const { defaultSignature } = useSelector((state) => state.mail);

  const openTemplatesModal = (action, id, type, redirectToUrl) => {
    if (action === 'open') {
      dispatch(setData({ action: '', id: '', type }));
    } else {
      dispatch(setData({ action, id, type }));
    }

    history.push(`${redirectToUrl}/template`);
  };

  const setMailData = (action, id, type) => {
    dispatch(setData({ action, id, type }));
  };

  const updateDefaultSignature = (template) => {
    if (!isEqual(defaultSignature, template)) {
      dispatch(setDefaultSignature(template));
    }
  };

  const pasteImageMatcher = useCallback((node, delta) => {
    const d = new Date().getTime();
    let src = node.getAttribute('src');

    return new Delta().insert({
      inlineImage: {
        value: src,
        props: d,
      },
    });
  }, []);

  const formats = [
    'header',
    'font',
    'size',
    'bold',
    'italic',
    'underline',
    'strike',
    'blockquote',
    'list',
    'bullet',
    'indent',
    'link',
    'image',
    'color',
    'inlineImage',
    'mailSignature',
    'align',
    'float',
    'height',
    'width',
    'size',
    'placeholder',
    'style',
    'alt',
    'background',
  ];

  const toolbarOptions = [
    [{ header: [1, 2, 3, 4, false] }],
    ['bold', 'italic', 'underline', 'strike'],
    [{ color: [] }, { background: [] }],
    ['link', 'image'],
    [{ align: [] }],
    [{ list: 'bullet' }, { list: 'ordered' }],
  ];

  const clipboard = {
    matchVisual: false,
  };

  const applyBlockToBody = (item, type) => {
    const range = qRef.current.editor.getSelection(true);
    let position = range ? range.index : 0;
    const contentCheck = qRef.current.editor.getContents();
    const exists = contentCheck.ops.map((o) => has(o, 'insert.mailSignature'));

    if (type === 'signature' && exists.length <= 0) {
      const payload = {
        html: item.content,
        key: item.emailSignatureId,
        type: 'signature',
      };
      qRef.current.editor.insertEmbed(0, `mailSignature`, payload);
      qRef.current.editor.setSelection(0, 'silent');
    } else {
      qRef.current.editor.updateContents(
        new Delta().retain(position).concat(item.structure)
      );
      qRef.current.editor.setSelection(range.index, 'silent');
    }
  };

  const imageHandler = useCallback(() => {
    let fileInput = qRef.current.editor.container.querySelector(
      'input.ql-image[type=file]'
    );
    if (fileInput == null) {
      fileInput = document.createElement('input');
      fileInput.setAttribute('type', 'file');
      fileInput.setAttribute(
        'accept',
        'image/png, image/gif, image/jpeg, image/bmp, image/x-icon'
      );
      fileInput.classList.add('ql-image');
      fileInput.classList.add('invisible');
      fileInput.addEventListener('change', () => {
        if (fileInput.files != null && fileInput.files[0] != null) {
          const fileSize = fileInput.files[0].size / 1024 / 1024;
          //console.log(fileSize);
          if (fileSize > 2.5) {
            dispatch(
              setAlert(
                'error',
                'Cannot add file',
                'File is too large! Size limit is 2.5MB'
              )
            );
          } else {
            let reader = new FileReader();
            reader.onload = (e) => {
              let range = qRef.current.editor.getSelection(true);
              qRef.current.editor.insertEmbed(range.index, 'inlineImage', {
                value: e.target.result,
                props: fileInput.files[0].name,
              });
              qRef.current.editor.updateContents(
                new Delta()
                  .retain(range.index)
                  .delete(range.length + 1)
                  .insert({
                    inlineImage: {
                      value: e.target.result,
                      props: fileInput.files[0].name,
                    },
                  }),
                'user'
              );
              qRef.current.editor.setSelection(range.index + 1, 'silent');
              fileInput.value = '';
            };
            reader.readAsDataURL(fileInput.files[0]);
          }
        }
      });
      qRef.current.editor.container.appendChild(fileInput);
    }
    fileInput.click();
  }, []);

  const onChangeContent = (content, delta, source, editor) => {
    setValue(editor.getContents());
    setContentValue(content);
  };

  const saveDraftMail = (payload) => {
    dispatch(setDraftMail(payload));
  };

  const saveMailSubject = (payload) => {
    dispatch(setMailSubject(payload));
  };

  const pastedImageHandler = useCallback((imageDataUrl, type, imageData) => {
    let range = qRef.current.editor.getSelection(true);
    qRef.current.editor.insertEmbed(range.index, 'inlineImage', {
      value: imageDataUrl,
      props: imageData.name,
    });
    qRef.current.editor.updateContents(
      new Delta()
        .retain(range.index)
        .delete(range.length + 1)
        .insert({
          inlineImage: {
            value: imageDataUrl,
            props: imageData.name,
          },
        }),
      'user'
    );
  }, []);

  return {
    value,
    setValue,
    contentValue,
    setContentValue,
    openTemplatesModal,
    setMailData,
    updateDefaultSignature,
    formats,
    toolbarOptions,
    clipboard,
    pasteImageMatcher,
    imageHandler,
    applyBlockToBody,
    onChangeContent,
    saveDraftMail,
    saveMailSubject,
    pastedImageHandler
  };
};

export default useMail;
