import React from 'react';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/solid';

const Pagination = ({ paginationData, paginationHandler }) => {
  return (
    <div className="flex justify-between">
      <div className="text-13 text-gray-400 mt-3">
        Showing {paginationData?.from} to {paginationData?.to} of{' '}
        {paginationData?.count} entries
      </div>
      <div className="text-13 text-gray-400 mt-3">
        <button
          className="cursor-pointer border border-current p-1"
          disabled={paginationData?.page === 1}
          onClick={() => paginationHandler(paginationData?.page)}
        >
          <span className="sr-only">Prev</span>
          <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
        </button>

        {paginationData?.nextPage && (
          <button
            className="ml-2 cursor-pointer border border-current p-1"
            onClick={() => paginationHandler(paginationData?.page, 'next')}
          >
            <span className="sr-only">Next</span>
            <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
          </button>
        )}
      </div>
    </div>
  );
};

export default Pagination;
