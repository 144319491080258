import axios from 'axios';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import Modal from 'components/Modal';
import ModalHeader from 'components/ModalHeader';
import ButtonLink from 'components/ButtonLink';
import useLoggedInUser from 'hooks/useLoggedInUser';
import Button from 'components/Button';
import Label from 'components/Forms/Label';
import { ErrorMessage, Field, FieldArray, Form, Formik } from 'formik';
import { joiAlertErrorsStringify } from 'utils/formatters';
import { array, number, object, string, date } from 'yup';
import { PlusIcon } from '@heroicons/react/outline';
import { TrashIcon } from '@heroicons/react/solid';
import Toggle from 'components/Toggle';
import { isEmpty } from 'lodash';
import FormikErrorNotification from 'components/FormikErrorNotification';
import useAlert from 'hooks/useAlert';
import RequiredAsterisk from 'components/Forms/RequiredAsterisk';
import { fetchAddons } from 'features/billing/billingSlice';
import useUpsell from 'hooks/useUpsell';
import SelectProductASINModal from './SelectProductASINModal';
import useAgencyClient from 'hooks/useAgencyClient';
import ClientAsinsSelectMultiple from 'components/ClientAsinsSelectMultiple';
import PriceLabel from 'components/PriceLabel';
import classNames from 'utils/classNames';

const CreateUpsellModal = ({
  open,
  setOpen,
  upsell,
  getUpsells,
  action = 'create',
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { user } = useLoggedInUser();
  const { agencyClient, defaultMarketplace } = useAgencyClient();
  const { addons, addonsPaginationParams } = useSelector(
    (state) => state.billing
  );
  const {
    STATUS,
    isItemWalmartListingOptimization,
    isItemPaidReviews,
    ITEM_CODES,
  } = useUpsell();
  const [oneTimeAddons, setOneTimeAddons] = useState(null);
  const { alertSuccess, alertError } = useAlert();
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState('');
  const [productASINs, setProductASINs] = useState([]);
  const [isOpenProductASINs, setIsOpenProductASINs] = useState(false);

  const getProductASINs = async (sellingPartnerId) => {
    await axios
      .get(`/agency/upsell/${sellingPartnerId}/productASIN`, {
        params: { marketplaceId: defaultMarketplace.marketplaceId },
      })
      .then((res) => {
        setProductASINs(res.data.data);
      });
  };

  useEffect(() => {
    if (agencyClient.account.sellingPartnerId) {
      getProductASINs(agencyClient.account.sellingPartnerId);
    }
  }, []);

  useEffect(() => {
    if (!addons) {
      dispatch(fetchAddons(addonsPaginationParams));
    } else {
      setOneTimeAddons(
        addons.filter((a) => a.type === 'one_time' && a.status === 'active')
      );
    }
  }, [addons]);

  const newAsin = {
    ...(action === 'create'
      ? {}
      : {
          upsellServiceAsinId: '',
          upsellId: '',
        }),
    asin: '',
    qty: 1,
    price: 0,
  };

  const emptyInitialValues = {
    agencyClientId: agencyClient.agencyClientId,
    requestedBy: user.userId,
    status: STATUS.DRAFT,
    note: '',
    details: {
      addonCode: '',
      type: '',
      name: '',
      description: '',
      qty: 1,
      price: 0,
      reviewFee: null,
      processingFee: null,
      tax: null,
      completionDate: null,
      productPrice: null,
      time: null,
    },
    serviceAsins: [],
    waived: false,
  };

  const [initialValues, setInitialValues] = useState(emptyInitialValues);

  useEffect(() => {
    setInitialValues(
      upsell
        ? {
            agencyClientId: upsell.agencyClientId,
            requestedBy: upsell.requestedBy,
            status: upsell.status,
            details: upsell.details,
            serviceAsins: upsell.serviceAsins?.map((d) => {
              return {
                upsellServiceAsinId: d.upsellServiceAsinId,
                upsellId: d.upsellId,
                asin: d.asin,
                qty: d.qty,
                price: d.price,
                reviewFee: d.reviewFee,
                processingFee: d.processingFee,
                tax: d.tax,
              };
            }),
            waived: upsell.waived,
            note: upsell.note,
          }
        : emptyInitialValues
    );
  }, [upsell]);

  const onDetailsChange = (e, setFieldValue, values) => {
    const selectedItem = oneTimeAddons.filter(
      (i) => i.addonCode === e.target.value
    )[0];

    if (selectedItem) {
      setFieldValue(`details.name`, selectedItem.name);
      setFieldValue(`details.description`, selectedItem.description);
      setFieldValue(`details.addonCode`, selectedItem.addonCode);
      setFieldValue(`details.type`, selectedItem.type);
      setFieldValue(`details.price`, selectedItem.price);
      if (action === 'update') {
        setFieldValue(`details.upsellId`, upsell.upsellId);
      }
      if (isItemPaidReviews(selectedItem.name)) {
        setFieldValue('details.processingFee', 3);
        setFieldValue('details.tax', 6.8);
        setFieldValue('details.reviewFee', 25);
      }
      if (isItemWalmartListingOptimization(selectedItem.name)) {
        setFieldValue(`serviceAsins`, [newAsin]);
      }
      if (selectedItem.addonCode === ITEM_CODES.AMAZON_SHOPPABLE_VIDEOS) {
        setFieldValue('details.tax', 9.9);
      }
      if (
        agencyClient.account.sellingPartnerId &&
        !isItemWalmartListingOptimization(selectedItem.name) &&
        isItemWalmartListingOptimization(values.details.name)
      ) {
        setFieldValue(`serviceAsins`, []);
      }
    }
  };

  const validationSchema = object().shape({
    agencyClientId: string().required('Required'),
    requestedBy: string().required('Required'),
    details: object().shape({
      addonCode: string().required('Upsell Item is required'),
      name: string().required('Upsell Name is required'),
      description: string().trim().required('Description is required'),
      qty: number()
        .required('Quantity is required')
        .positive('Invalid')
        .integer('Invalid'),
      price: number().required('Price is required'),
      reviewFee: number()
        .nullable()
        .when('addonCode', {
          is: ITEM_CODES.REVIEWS,
          then: number().required().min(0).typeError('Review Fee is required'),
        }),
      processingFee: number()
        .nullable()
        .when('addonCode', {
          is: ITEM_CODES.REVIEWS,
          then: number()
            .required()
            .min(0)
            .typeError('Processing Fee is required'),
        }),
      tax: number()
        .nullable()
        .when('addonCode', {
          is: ITEM_CODES.REVIEWS,
          then: number().required().min(0).typeError('Tax is required'),
        })
        .when('addonCode', {
          is: ITEM_CODES.AMAZON_SHOPPABLE_VIDEOS,
          then: number().required().min(0).typeError('Tax is required'),
        }),
      productPrice: number()
        .nullable()
        .when('addonCode', {
          is: ITEM_CODES.AMAZON_SHOPPABLE_VIDEOS,
          then: number()
            .required()
            .min(0)
            .typeError('Product price is required'),
        }),
      time: number()
        .nullable()
        .when('addonCode', {
          is: ITEM_CODES.AMAZON_SHOPPABLE_VIDEOS,
          then: number().required().min(0).typeError('Time is required'),
        }),
      completionDate: date().nullable(),
    }),

    serviceAsins: array()
      .of(
        object().shape({
          asin: string().required('Service ASIN/Item is required'),
          qty: number().test(
            'test-qty',
            'Quantity is required',
            async (value, ctx) => {
              if (ctx.from[1].value.details.addonCode === ITEM_CODES.REVIEWS) {
                const numberCheck = number().required().min(0);
                return await numberCheck.isValid(value);
              } else return true;
            }
          ),
          price: number().test(
            'test-price',
            'Price is required',
            async (value, ctx) => {
              if (ctx.from[1].value.details.addonCode === ITEM_CODES.REVIEWS) {
                const numberCheck = number().required().min(0);
                return await numberCheck.isValid(value);
              } else {
                return true;
              }
            }
          ),
        })
      )
      .test(
        'required-if-paid-reviews',
        'ASINS to review is required',
        (value, ctx) => {
          if (ctx.parent.details.addonCode === ITEM_CODES.REVIEWS)
            return value.length > 0;

          return true;
        }
      ),
    status: string().required('Required'),
  });

  const onSubmit = async (values) => {
    if (values.selectedAsins) delete values.selectedAsins;

    setLoading(true);
    try {
      const response = await axios.post(
        `/agency/upsell${action === 'create' ? '' : `/${upsell.upsellId}`}`,
        values
      );

      alertSuccess('Upsell Created', response.data.message);
      setOpen(false);
      getUpsells();
      if (values.waived && values.status === STATUS.AWAITING_APPROVAL)
        history.push(
          `/clients/${agencyClient.agencyClientId}/billing/creditnotes`
        );
    } catch (error) {
      const errorMessages = joiAlertErrorsStringify(error);
      alertError(error.response.data.message, errorMessages);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      as={'div'}
      align="top"
      noOverlayClick={true}
      zIndex="z-20"
    >
      <div className="inline-block w-full max-w-lg my-24 overflow-hidden text-left transition-all transform bg-white shadow-xl rounded-xl ">
        <ModalHeader
          title={`${action} Upsell`}
          setOpen={setOpen}
          fontSize="text-xl"
          fontStyle="font-bold"
          px="px-4 md:px-8"
          py="py-4 md:py-8"
          xAlign="items-start"
          classes="capitalize"
        />
        <div className="px-8 overflow-y-auto" style={{ height: '55vh' }}>
          <Formik
            initialValues={initialValues}
            onSubmit={onSubmit}
            validationSchema={validationSchema}
            enableReinitialize={true}
          >
            {({ handleChange, handleSubmit, setFieldValue, values }) => (
              <Form>
                <FormikErrorNotification />
                <div className="mt-6">
                  {!isEmpty(values.details) && (
                    <div className="grid grid-cols-12 gap-4">
                      <div className="col-span-12 text-sm">
                        <Label>
                          Upsell Item
                          <RequiredAsterisk sup={false} />
                        </Label>
                        <Field
                          name={`details.addonCode`}
                          placeholder="Select Upsell Item"
                          className="form-select text-sm"
                          as="select"
                          onChange={(e) =>
                            onDetailsChange(e, setFieldValue, values)
                          }
                        >
                          <option value="">Select Upsell Item</option>
                          {oneTimeAddons &&
                            oneTimeAddons.map((obj) => (
                              <option value={obj.addonCode}>{obj.name}</option>
                            ))}
                        </Field>
                        <ErrorMessage
                          name={`details.addonCode`}
                          component="div"
                          className="text-red-700 font-normal text-xs"
                        />
                      </div>

                      {!(
                        isItemPaidReviews(values.details.name) ||
                        values.details.addonCode ===
                          ITEM_CODES.AMAZON_SHOPPABLE_VIDEOS
                      ) && (
                        <>
                          <div className="col-span-6">
                            <Label>
                              Quantity
                              <RequiredAsterisk sup={false} />
                            </Label>
                            <Field
                              name={`details.qty`}
                              type="number"
                              min="1"
                              className="form-input text-sm"
                            />
                            <ErrorMessage
                              name={`details.qty`}
                              component="div"
                              className="text-red-700 font-normal text-xs"
                            />
                          </div>
                          <div className="col-span-6">
                            <Label>
                              Unit Price
                              <RequiredAsterisk sup={false} />
                            </Label>
                            <div className="relative">
                              <span className="absolute transform -translate-y-1/2 top-1/2 left-4 text-xl font-semibold text-grayscale-700">
                                $
                              </span>
                              <Field
                                name={`details.price`}
                                type="number"
                                className="form-input text-sm pl-10"
                              />
                            </div>
                            <ErrorMessage
                              name={`details.price`}
                              component="div"
                              className="text-red-700 font-normal text-xs"
                            />
                          </div>
                        </>
                      )}

                      {isItemPaidReviews(values.details.name) && (
                        <>
                          <div className="col-span-6">
                            <Label>
                              Review Fee
                              <RequiredAsterisk sup={false} />
                            </Label>
                            <div className="relative">
                              <span className="absolute transform -translate-y-1/2 top-1/2 left-4 text-xl font-semibold text-grayscale-700">
                                $
                              </span>
                              <Field
                                name={`details.reviewFee`}
                                type="number"
                                className="form-input text-sm pl-10"
                              />
                            </div>
                            <ErrorMessage
                              name={`details.reviewFee`}
                              component="div"
                              className="text-red-700 font-normal text-xs"
                            />
                          </div>
                          <div className="col-span-6">
                            <Label>
                              Processing Fee
                              <RequiredAsterisk sup={false} />
                            </Label>
                            <div className="relative">
                              <span className="absolute transform -translate-y-1/2 top-1/2 left-4 text-xl font-semibold text-grayscale-700">
                                %
                              </span>
                              <Field
                                name={`details.processingFee`}
                                type="number"
                                className="form-input text-sm pl-10"
                              />
                            </div>
                            <ErrorMessage
                              name={`details.processingFee`}
                              component="div"
                              className="text-red-700 font-normal text-xs"
                            />
                          </div>
                          <div className="col-span-6">
                            <Label>
                              Tax
                              <RequiredAsterisk sup={false} />
                            </Label>
                            <div className="relative">
                              <span className="absolute transform -translate-y-1/2 top-1/2 left-4 text-xl font-semibold text-grayscale-700">
                                %
                              </span>
                              <Field
                                name={`details.tax`}
                                type="number"
                                className="form-input text-sm pl-10"
                              />
                            </div>
                            <ErrorMessage
                              name={`details.tax`}
                              component="div"
                              className="text-red-700 font-normal text-xs"
                            />
                          </div>
                          <div className="col-span-6">
                            <Label>Estimate Completion Date</Label>
                            <Field
                              name={`details.completionDate`}
                              type="date"
                              className="form-input text-sm"
                            />
                            <ErrorMessage
                              name={`details.completionDate`}
                              component="div"
                              className="text-red-700 font-normal text-xs"
                            />
                          </div>
                        </>
                      )}

                      {values.details.addonCode ===
                        ITEM_CODES.AMAZON_SHOPPABLE_VIDEOS && (
                        <>
                          <div className="col-span-6">
                            <Label>Product Price</Label>
                            <Field
                              name={`details.productPrice`}
                              type="number"
                              className="form-input text-sm"
                            />
                            <ErrorMessage
                              name={`details.productPrice`}
                              component="div"
                              className="text-red-700 font-normal text-xs"
                            />
                          </div>

                          <div className="col-span-6">
                            <Label>
                              Time (seconds)
                              <RequiredAsterisk sup={false} />
                            </Label>
                            <Field
                              name={`details.time`}
                              type="number"
                              className="form-input text-sm"
                            />
                            <ErrorMessage
                              name={`details.time`}
                              component="div"
                              className="text-red-700 font-normal text-xs"
                            />
                          </div>

                          <div className="col-span-6">
                            <Label>
                              Amount
                              <RequiredAsterisk sup={false} />
                            </Label>
                            <div className="relative">
                              <span className="absolute transform -translate-y-1/2 top-1/2 left-4 text-xl font-semibold text-grayscale-700">
                                $
                              </span>
                              <Field
                                name={`details.price`}
                                type="number"
                                className="form-input text-sm pl-10"
                              />
                            </div>
                            <ErrorMessage
                              name={`details.price`}
                              component="div"
                              className="text-red-700 font-normal text-xs"
                            />
                          </div>

                          <div className="col-span-6">
                            <Label>
                              Tax
                              <RequiredAsterisk sup={false} />
                            </Label>
                            <div className="relative">
                              <span className="absolute transform -translate-y-1/2 top-1/2 left-4 text-xl font-semibold text-grayscale-700">
                                %
                              </span>
                              <Field
                                name={`details.tax`}
                                type="number"
                                className="form-input text-sm pl-10"
                              />
                            </div>
                            <ErrorMessage
                              name={`details.tax`}
                              component="div"
                              className="text-red-700 font-normal text-xs"
                            />
                          </div>
                        </>
                      )}

                      <div className="col-span-12 flex flex-row justify-between items-center">
                        <Label>Waive Upsell Fee</Label>
                        <Toggle
                          onChange={(e) => setFieldValue('waived', e)}
                          checked={values.waived}
                        />
                      </div>

                      <div className="col-span-12">
                        <Label>
                          Upsell Description
                          <RequiredAsterisk sup={false} />
                        </Label>
                        <Field
                          name={`details.description`}
                          placeholder="Add information related to this upsell"
                          as="textarea"
                          rows={5}
                          className="form-input text-sm"
                        />
                        <ErrorMessage
                          name={`details.description`}
                          component="div"
                          className="text-red-700 font-normal text-xs"
                        />
                      </div>
                    </div>
                  )}
                </div>
                <div className="font-bold text-lg text-gray-800 mt-8">
                  {isItemPaidReviews(values.details.name)
                    ? 'ASINs to review'
                    : isItemWalmartListingOptimization(values.details.name)
                    ? 'Service Items'
                    : 'Service ASIN'}

                  <FieldArray name="serviceAsins">
                    {({ insert, remove, push, pop }) => (
                      <div>
                        {values.serviceAsins.length > 0 &&
                          values.serviceAsins.map((asin, index) => (
                            <div
                              className="grid grid-cols-2"
                              key={`idx-asin-${index}`}
                            >
                              <div
                                className={classNames(
                                  isItemPaidReviews(values.details.name)
                                    ? 'mt-8'
                                    : 'mt-4',
                                  'col-span-2 flex justify-between items-center'
                                )}
                              >
                                <span className="font-bold text-lg text-gray-500">
                                  {isItemWalmartListingOptimization(
                                    values.details.name
                                  )
                                    ? ' Items '
                                    : ' ASIN '}
                                  &nbsp;{index + 1}
                                  <RequiredAsterisk sup={false} />
                                </span>

                                {index === 0 &&
                                  agencyClient.account.sellingPartnerId &&
                                  !isItemWalmartListingOptimization(
                                    values.details.name
                                  ) && (
                                    <span className="cursor-pointer hover:text-red-500 pl-2">
                                      <TrashIcon
                                        onClick={() => remove(index)}
                                        className="w-5 h-5 "
                                      />
                                    </span>
                                  )}

                                <span className="cursor-pointer hover:text-red-500 pl-2">
                                  <TrashIcon
                                    onClick={() => remove(index)}
                                    className="w-5 h-5 "
                                  />
                                </span>
                              </div>

                              <div className="col-span-2">
                                <Field
                                  name={`serviceAsins.${index}.asin`}
                                  placeholder={
                                    isItemWalmartListingOptimization(
                                      values.details.name
                                    )
                                      ? 'Enter Item'
                                      : 'Enter ASIN'
                                  }
                                  className="form-input disabled-white"
                                  type="text"
                                  disabled={
                                    agencyClient.account.sellingPartnerId &&
                                    !isItemWalmartListingOptimization(
                                      values.details.name
                                    )
                                  }
                                />

                                <ErrorMessage
                                  name={`serviceAsins.${index}.asin`}
                                  component="div"
                                  className="text-red-700 font-normal text-xs"
                                />
                              </div>

                              {isItemPaidReviews(values.details.name) && (
                                <>
                                  <div className="col-span-1">
                                    <Label>
                                      Quantity
                                      <RequiredAsterisk sup={false} />
                                    </Label>
                                    <Field
                                      name={`serviceAsins.${index}.qty`}
                                      type="number"
                                      min="1"
                                      className="form-input text-sm"
                                    />
                                    <ErrorMessage
                                      name={`serviceAsins.${index}.qty`}
                                      component="div"
                                      className="text-red-700 font-normal text-xs"
                                    />
                                  </div>
                                  <div className="col-span-1">
                                    <Label>
                                      Unit Price
                                      <RequiredAsterisk sup={false} />
                                    </Label>
                                    <div className="relative">
                                      <span className="absolute transform -translate-y-1/2 top-1/2 left-4 text-xl font-semibold text-grayscale-700">
                                        $
                                      </span>
                                      <Field
                                        name={`serviceAsins.${index}.price`}
                                        type="number"
                                        className="form-input text-sm pl-10"
                                      />
                                    </div>
                                    <ErrorMessage
                                      name={`serviceAsins.${index}.price`}
                                      component="div"
                                      className="text-red-700 font-normal text-xs"
                                    />
                                  </div>
                                  <div className="col-span-1 justify-between flex items-center text-sm mt-1 pr-4 p-1">
                                    <Label>Product Cost</Label>
                                    <PriceLabel
                                      currency="$"
                                      price={
                                        values.serviceAsins[index].qty *
                                        values.serviceAsins[index].price
                                      }
                                    />
                                  </div>
                                  <div className="col-span-1 justify-between flex items-center text-sm mt-1 pl-4 p-1">
                                    <Label>Review Fee</Label>
                                    <PriceLabel
                                      currency="$"
                                      price={
                                        values.serviceAsins[index].qty *
                                        values.details.reviewFee
                                      }
                                    />
                                  </div>
                                  <div className="col-span-1 justify-between flex items-center text-sm pr-4 p-1">
                                    <Label>Processing Fee</Label>
                                    <PriceLabel
                                      currency="$"
                                      price={
                                        (values.details.processingFee *
                                          (values.serviceAsins[index].qty *
                                            values.serviceAsins[index].price)) /
                                        100
                                      }
                                    />
                                  </div>
                                  <div className="col-span-1 justify-between flex items-center text-sm pl-4 p-1">
                                    <Label>Tax</Label>
                                    <PriceLabel
                                      currency="$"
                                      price={
                                        (values.details.tax *
                                          (values.serviceAsins[index].qty *
                                            values.serviceAsins[index].price)) /
                                        100
                                      }
                                    />
                                  </div>
                                </>
                              )}
                            </div>
                          ))}
                        <div className="col-span-12 mt-6">
                          {agencyClient.account.sellingPartnerId &&
                          !isItemWalmartListingOptimization(
                            values.details.name
                          ) ? (
                            <ClientAsinsSelectMultiple
                              asins={productASINs}
                              onChange={(asin, action) => {
                                if (action === 'add') {
                                  push({
                                    asin: asin.asin,
                                    qty: 1,
                                    price: asin.price,
                                  });
                                } else {
                                  const idx = values.serviceAsins.findIndex(
                                    (s) => s.asin === asin.asin
                                  );
                                  remove(idx);
                                }
                              }}
                              selectedIds={values.serviceAsins}
                              width="w-full"
                              containerWidth="w-full"
                              height="h-52"
                              itemsLength={values.serviceAsins.length}
                              onSelectAll={(action, alreadySelected) => {
                                if (action === 'select') {
                                  productASINs
                                    .filter(
                                      (p) => !alreadySelected.includes(p.asin)
                                    )
                                    .map((p) =>
                                      push({
                                        asin: p.asin,
                                        qty: 1,
                                        price: p.price,
                                      })
                                    );
                                } else {
                                  setFieldValue('serviceAsins', []);
                                }
                              }}
                            />
                          ) : (
                            <Button
                              classes={
                                'rounded-full flex flex-row justify-center w-full'
                              }
                              border=" border-2 border-blue-900 tracking-widest font-bold"
                              hoverColor="blue-100"
                              textColor="blue-900"
                              bgColor="bg-white"
                              py={1}
                              onClick={() => {
                                agencyClient.account.sellingPartnerId &&
                                !isItemWalmartListingOptimization(
                                  values.details.name
                                )
                                  ? setIsOpenProductASINs(true)
                                  : push(newAsin);
                              }}
                            >
                              <PlusIcon className="h-4 w-4 mr-2" />
                              {isItemPaidReviews(values.details.name)
                                ? 'Add ASINs to Review'
                                : isItemWalmartListingOptimization(
                                    values.details.name
                                  )
                                ? 'Add Service Items'
                                : 'Add Service ASIN'}
                            </Button>
                          )}
                        </div>
                      </div>
                    )}
                  </FieldArray>
                </div>

                <div className="flex flex-col mt-12">
                  <Label>Note</Label>
                  <span className="text-sm">
                    <Field
                      name="note"
                      as="textarea"
                      rows={5}
                      placeholder="Add any important information related to this upsell"
                      className="form-input text-sm"
                    ></Field>
                  </span>
                </div>
                <div className="flex justify-between mt-8 sticky bottom-0 p-8 bg-white border-t -mx-8">
                  {action === 'create' || values.status === STATUS.DRAFT ? (
                    <ButtonLink
                      classes="tracking-2 font-bold"
                      color="blue"
                      type="submit"
                      onClick={() => setFieldValue('status', STATUS.DRAFT)}
                      showLoading={
                        values.status === STATUS.DRAFT ? true : false
                      }
                      spinnerColor="success"
                      loading={loading}
                    >
                      Save
                      {action !== 'create' && values.status === STATUS.DRAFT
                        ? ''
                        : ' as Draft'}
                    </ButtonLink>
                  ) : (
                    <ButtonLink
                      classes="tracking-wider font-bold"
                      color="blue"
                      onClick={() => setOpen(false)}
                    >
                      Cancel
                    </ButtonLink>
                  )}
                  <Button
                    classes="border-0 font-bold tracking-2 text-sm"
                    bgColor="secondary"
                    hoverColor="secondary-light"
                    roundedSize="full"
                    textColor="white"
                    px={12}
                    py={2}
                    type="submit"
                    loading={loading}
                    showLoading={
                      values.status === STATUS.AWAITING_APPROVAL ? true : false
                    }
                    onClick={() =>
                      setFieldValue('status', STATUS.AWAITING_APPROVAL)
                    }
                  >
                    Submit
                  </Button>
                </div>
                <SelectProductASINModal
                  open={isOpenProductASINs}
                  setOpen={setIsOpenProductASINs}
                  search={search}
                  setSearch={setSearch}
                  productASINs={productASINs}
                  action={action}
                  upsell={upsell}
                />
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </Modal>
  );
};

export default CreateUpsellModal;
