import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import ReactTooltip from 'react-tooltip';
import { Link } from 'react-router-dom';
import PageHeader from 'components/PageHeader';
import { MenuIcon, ViewGridIcon } from '@heroicons/react/outline';
import usePermissions from 'hooks/usePermissions';
import useQueryParams from 'hooks/useQueryParams';
import Select from 'components/Forms/Select';
import { fetchSalesClients } from './salesSlice';
import qs from 'qs';
import classNames from 'utils/classNames';
import ListView from './components/ListView';
import BoardView from './components/BoardView';
import BoardViewSearchField from './components/BoardViewSearchField';
import Button from 'components/Button';
import CustomizeColumnBtn from './components/CustomizeColumnBtn/CustomizeColumnBtn';
import useLoggedInUser from 'hooks/useLoggedInUser';
import { setAlert } from 'features/alerts/alertsSlice';
import { Avatar } from 'components';
import moment from 'moment';
import { getFileThumbnailUrl } from 'utils/file';
import {
  currencyFormatter,
  decimalFormatter,
  getNameInitials,
  percentageFormatter,
} from 'utils/formatters';
import { STATUSES, STATUSES_PROSPECT } from './utils/constants';
import { CalendarIcon } from '@heroicons/react/outline';
import DropdownMenu from 'components/DropdownMenu';
import DateTimePicker from 'components/DateTimePicker';
import useClientAssignees from 'features/clients/Client/useClientAssignees';

Date.prototype.addDays = function (days) {
  this.setDate(this.getDate() + parseInt(days));

  return this;
};
const Overview = () => {
  const dispatch = useDispatch();
  const { userCan } = usePermissions();
  const { SALES_REPRESENTATIVE_ASSIGNEE } = useClientAssignees();
  const [loading, setLoading] = useState(false);
  const { salesClients, paginationParams } = useSelector(
    (state) => state.sales
  );

  const { params, updateParams, sortParam } = useQueryParams(paginationParams);
  const [searchParams, setSearchParams] = useState({
    search: '',
    creationDate: '',
    lastActivityDate: '',
    closeDate: '',
    dealType: '',
    dealOwner: '',
  });
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [dealOwners, setDealOwners] = useState([]);
  const [importing, setImporting] = useState(false);

  const DEFAULT_COLUMNS = [
    {
      dataField: 'lead.companyName',
      text: 'Client Name',
      sort: true,
      show: true,
      hideable: false,
      headerStyle: {
        minWidth: '250px',
      },
      formatter: (cell, row) => {
        return userCan('sales.profiles.view') ? (
          <Link
            className="text-red-500 hover:text-red-700"
            to={`/sales/client/${row.salesClientId}`}
            title="Profile"
          >
            {cell}
          </Link>
        ) : (
          cell
        );
      },
    },
    // {
    //   dataField: 'createdAt',
    //   text: 'Date Added',
    //   sort: true,
    //   headerStyle: {
    //     minWidth: '180px',
    //   },
    //   formatter: (cell, row) => {
    //     return (
    //       <span className="font-normal">
    //         {moment(cell).format('YYYY-MM-DD')}
    //       </span>
    //     );
    //   },
    //   show: true,
    //   hideable: true,
    // },
    {
      dataField: 'status',
      text: 'Status',
      sort: true,
      headerStyle: {
        minWidth: '180px',
      },
      formatter: (cell, row) => {
        return (
          <>
            <div
              className="h-10 flex flex-col justify-center"
              id={row.salesClientId}
              onMouseEnter={(e) => {
                if (e.currentTarget.id === row.salesClientId) {
                  setHoverSalesId(e.currentTarget.id);
                  setHoverColumn('status');
                }
              }}
              onMouseLeave={(e) => {
                if (e.currentTarget.id === row.salesClientId) {
                  setHoverSalesId(null);
                  setHoverColumn('');
                }
              }}
            >
              {hoverSalesId === row.salesClientId &&
              hoverColumn === 'status' ? (
                <Select
                  className={`appearance-none border-white placeholder-gray-400 bg-gray-200 w-44 cursor-pointer `}
                  value={row.status}
                  onChange={(e) =>
                    onUpdate(e.target.value, 'status', row.salesClientId, row)
                  }
                  disabled={
                    row.salesClientId === 'add' ||
                    row.status === 'Client' ||
                    row.status === 'Transfer to Operations'
                  }
                  padding={'pl-3 py-2'}
                >
                  {STATUSES_PROSPECT.some((el) => el.value === row.status) &&
                    STATUSES_PROSPECT.map((s) => {
                      if (s.value !== 'Lost') {
                        return (
                          <option key={s.value} value={s.value}>
                            {s.label}
                          </option>
                        );
                      }
                    })}
                  {row.status === 'Proposal'
                    ? STATUSES.map((s) => {
                        if (s.value === 'Proposal' || s.value === 'Lost') {
                          return (
                            <option key={s.value} value={s.value}>
                              {s.label}
                            </option>
                          );
                        }
                      })
                    : STATUSES.map((s) => {
                        return (
                          <option key={s.value} value={s.value}>
                            {s.label}
                          </option>
                        );
                      })}
                  {/* show since removed transfer to operations in selection  */}
                  {row.status === 'Transfer to Operations' && (
                    <option
                      key="Transfer to Operations"
                      value="Transfer to Operations"
                    >
                      Transfer to Operations
                    </option>
                  )}
                </Select>
              ) : (
                <span>{cell}</span>
              )}
            </div>
          </>
        );
      },
      show: true,
      hideable: true,
      type: 'deal',
    },
    {
      dataField: 'closedDealDate',
      text: 'Close Date (GMT+8)',
      sort: true,
      headerStyle: {
        minWidth: '180px',
      },
      formatter: (cell, row) => {
        return (
          <>
            <div
              className="h-10 flex flex-col justify-center"
              id={row.salesClientId}
              onMouseEnter={(e) => {
                if (e.currentTarget.id === row.salesClientId) {
                  setHoverSalesId(e.currentTarget.id);
                  setHoverColumn('closedDealDate');
                }
              }}
              onMouseLeave={(e) => {
                if (e.currentTarget.id === row.salesClientId) {
                  setHoverSalesId(null);
                  setHoverColumn('');
                }
              }}
            >
              {hoverSalesId === row.salesClientId &&
              hoverColumn === 'closedDealDate' ? (
                <div className="flex rounded-md appearance-none border-white placeholder-gray-400 bg-gray-200 p-1 w-max block focus:outline-none focus:ring-gray-900 focus:border-gray-900 sm:text-sm disabled:bg-gray-100">
                  <DropdownMenu
                    title={
                      <span className={''} title="click to update close date">
                        {cell ? (
                          <p className="flex justify-between w-full px-2">
                            <span className="flex flex-col justify-center pr-4">
                              {moment.utc(cell).format('YYYY-MM-DD')}
                            </span>
                            <CalendarIcon className="w-6 h-6" />
                          </p>
                        ) : (
                          <p className="flex justify-between w-full px-2">
                            <span className="flex flex-col justify-center pr-2">
                              yyyy-mm-dd
                            </span>
                            <CalendarIcon className="w-6 h-6" />
                          </p>
                        )}
                      </span>
                    }
                    titleClasses="flex items-center"
                    buttonBg="bg-transparent"
                    buttonFontWeight="font-normal"
                    buttonFocus=""
                    buttonClasses=""
                    hoverClasses=""
                    textColor="text-gray-600"
                    classes="text-sm"
                    buttonRounded=""
                    // hoverText="hover:text-red-500"
                    dropdownWidth="w-88"
                    padding=""
                    position={''}
                    zIndex="z-10"
                    hideArrow
                  >
                    <DateTimePicker
                      value={
                        cell ? moment.utc(cell).format('YYYY-MM-DD') : null
                      }
                      onChange={(e) => {
                        handleCloseDateChange(
                          moment.utc(e).format('YYYY-MM-DD'),
                          row.salesClientId
                        );
                      }}
                      minDate={moment()
                        .tz(moment.tz.guess())
                        .startOf('d')
                        .toDate()}
                    />
                  </DropdownMenu>
                </div>
              ) : (
                <span className="font-normal">
                  {cell ? moment.utc(cell).format('YYYY-MM-DD') : ''}
                </span>
              )}
            </div>
          </>
        );
      },
      show: true,
      hideable: true,
      type: 'deal',
    },
    {
      dataField: 'salesRep',
      text: 'Deal Owner',
      sort: true,
      headerStyle: {
        minWidth: '80px',
      },
      show: true,
      hideable: true,
      type: 'deal',
      formatter: (cell, row) => {
        return (
          row &&
          row['salesRep.firstName'] && (
            <div className="flex space-x-1 items-center">
              <Avatar
                initials={getNameInitials(
                  row['salesRep.firstName'],
                  row['salesRep.lastName']
                )}
                imageSrc={getFileThumbnailUrl(
                  row['salesRep.avatar.name'],
                  row['salesRep.avatar.extension'],
                  row['salesRep.avatar.conversions']
                )}
              />
              <span className="text-13 text-grayscale-800 leading-1.5">
                {row['salesRep.firstName']} {row['salesRep.lastName']}
              </span>
            </div>
          )
        );
      },
    },
    {
      dataField: 'totalAmountPaid',
      text: 'Amount',
      sort: true,
      headerStyle: {
        minWidth: '180px',
      },
      formatter: (cell, row) => {
        return (
          <>
            <div
              className="h-10 flex flex-col justify-center"
              id={row.salesClientId}
              onMouseEnter={(e) => {
                if (e.currentTarget.id === row.salesClientId) {
                  setHoverSalesId(e.currentTarget.id);
                  setHoverColumn('dealAmount');
                }
              }}
              onMouseLeave={(e) => {
                if (e.currentTarget.id === row.salesClientId) {
                  setHoverSalesId(null);
                  setHoverColumn('');
                }
              }}
            >
              {hoverSalesId === row.salesClientId &&
              hoverColumn === 'dealAmount' ? (
                <div className="flex appearance-none relative rounded-md block w-32 ">
                  <div className="absolute inset-y-0 left-0 flex pl-2 items-center pointer-events-none">
                    <span className="text-gray-500 sm:text-sm">$</span>
                  </div>
                  <input
                    name="search"
                    onKeyDown={(e) =>
                      onUpdateDealAmount(e, e.target.value, row.salesClientId)
                    }
                    type="number"
                    placeholder={decimalFormatter(cell)}
                    className={
                      'pl-5 focus:ring-gray-200 focus:border-gray-200 hover:border-gray-200 sm:text-sm disabled:bg-gray-200 placeholder-gray-300 border-white rounded-md'
                    }
                  />
                </div>
              ) : (
                <div className="flex justify-between sm:text-sm">
                  <span className="font-sm">{currencyFormatter(cell)}</span>
                </div>
              )}
            </div>
          </>
        );
      },
      show: true,
      hideable: true,
      type: 'deal',
    },
    {
      dataField: 'lead.lead',
      text: 'First Name',
      sort: true,
      headerStyle: {
        minWidth: '180px',
      },
      formatter: (cell, row) => {
        return <span className="font-normal">{row['lead.lead']}</span>;
      },
      show: false,
      hideable: true,
      type: 'contactDetails',
    },
    {
      dataField: 'lead.leadLastName',
      text: 'Last Name',
      sort: true,
      headerStyle: {
        minWidth: '180px',
      },
      formatter: (cell, row) => {
        return <span className="font-normal">{row['lead.leadLastName']}</span>;
      },
      show: false,
      hideable: true,
      type: 'contactDetails',
    },
    {
      dataField: 'lead.phoneNumber',
      text: 'Phone Number',
      sort: true,
      headerStyle: {
        minWidth: '180px',
      },
      formatter: (cell, row) => {
        return <span className="font-normal">{row['lead.phoneNumber']}</span>;
      },
      show: false,
      hideable: true,
      type: 'contactDetails',
    },
    {
      dataField: 'lead.email',
      text: 'Email Address',
      sort: true,
      headerStyle: {
        minWidth: '180px',
      },
      formatter: (cell, row) => {
        return <span className="font-normal">{row['lead.email']}</span>;
      },
      show: false,
      hideable: true,
      type: 'contactDetails',
    },
    {
      dataField: 'lead.address',
      text: 'Address',
      sort: true,
      headerStyle: {
        minWidth: '180px',
      },
      formatter: (cell, row) => {
        return <span className="font-normal">{row['lead.address']}</span>;
      },
      show: false,
      hideable: true,
      type: 'contactDetails',
    },
    {
      dataField: 'lead.website',
      text: 'Website',
      sort: true,
      headerStyle: {
        minWidth: '180px',
      },
      formatter: (cell, row) => {
        return <span className="font-normal">{row['lead.website']}</span>;
      },
      show: false,
      hideable: true,
      type: 'contactDetails',
    },
    {
      dataField: 'lead.source',
      text: 'Source',
      sort: true,
      headerStyle: {
        minWidth: '180px',
      },
      formatter: (cell, row) => {
        return <span className="font-normal">{row['lead.source']}</span>;
      },
      show: false,
      hideable: true,
      type: 'contactDetails',
    },
    {
      dataField: 'lead.facebook',
      text: 'Facebook URL',
      sort: true,
      headerStyle: {
        minWidth: '180px',
      },
      formatter: (cell, row) => {
        return <span className="font-normal">{row['lead.facebook']}</span>;
      },
      show: false,
      hideable: true,
      type: 'contactDetails',
    },
    {
      dataField: 'lead.instagram',
      text: 'Instagram URL',
      sort: true,
      headerStyle: {
        minWidth: '180px',
      },
      formatter: (cell, row) => {
        return <span className="font-normal">{row['lead.instagram']}</span>;
      },
      show: false,
      hideable: true,
      type: 'contactDetails',
    },
    {
      dataField: 'lead.linkedInProfileURL',
      text: 'Linkedin Profile URL',
      sort: true,
      headerStyle: {
        minWidth: '180px',
      },
      formatter: (cell, row) => {
        return (
          <span className="font-normal">{row['lead.linkedInProfileURL']}</span>
        );
      },
      show: false,
      hideable: true,
      type: 'contactDetails',
    },
    {
      dataField: 'decisionMaker',
      text: 'Decision Maker',
      sort: true,
      headerStyle: {
        minWidth: '180px',
      },
      formatter: (cell, row) => {
        return <span className="font-normal">{row['decisionMaker']}</span>;
      },
      show: false,
      hideable: true,
      type: 'businessInformation',
    },
    {
      dataField: 'email',
      text: 'Email Address',
      sort: true,
      headerStyle: {
        minWidth: '180px',
      },
      formatter: (cell, row) => {
        return <span className="font-normal">{row['email']}</span>;
      },
      show: false,
      hideable: true,
      type: 'businessInformation',
    },
    {
      dataField: 'aboutUs',
      text: 'About Us',
      sort: true,
      headerStyle: {
        minWidth: '180px',
      },
      formatter: (cell, row) => {
        return <span className="font-normal">{row['aboutUs']}</span>;
      },
      show: false,
      hideable: true,
      type: 'businessInformation',
    },
    {
      dataField: 'Goals',
      text: 'Goals',
      sort: true,
      headerStyle: {
        minWidth: '180px',
      },
      formatter: (cell, row) => {
        return <span className="font-normal">{row['Goals']}</span>;
      },
      show: false,
      hideable: true,
      type: 'businessInformation',
    },
    {
      dataField: 'painPoints',
      text: 'Pain Points',
      sort: true,
      headerStyle: {
        minWidth: '180px',
      },
      formatter: (cell, row) => {
        return <span className="font-normal">{row['painPoints']}</span>;
      },
      show: false,
      hideable: true,
      type: 'businessInformation',
    },
    {
      dataField: 'mainIssue',
      text: 'Main Issue with Amazon',
      sort: true,
      headerStyle: {
        minWidth: '180px',
      },
      formatter: (cell, row) => {
        return <span className="font-normal">{row['mainIssue']}</span>;
      },
      show: false,
      hideable: true,
      type: 'businessInformation',
    },
    {
      dataField: 'avgMonthlySalesOnAmazon',
      text: 'Average Monthly Sales on Amazon',
      sort: true,
      headerStyle: {
        minWidth: '180px',
      },
      formatter: (cell, row) => {
        return (
          <span className="font-normal">
            {currencyFormatter(row['avgMonthlySalesOnAmazon'])}
          </span>
        );
      },
      show: false,
      hideable: true,
      type: 'businessInformation',
    },
    {
      dataField: 'avgMonthlySalesOutsideAmazon',
      text: 'Average Monthly Sales Outside Amazon',
      sort: true,
      headerStyle: {
        minWidth: '180px',
      },
      formatter: (cell, row) => {
        return (
          <span className="font-normal">
            {currencyFormatter(row['avgMonthlySalesOutsideAmazon'])}
          </span>
        );
      },
      show: false,
      hideable: true,
      type: 'businessInformation',
    },
    {
      dataField: 'ppcSpend',
      text: 'PPC Spend',
      sort: true,
      headerStyle: {
        minWidth: '180px',
      },
      formatter: (cell, row) => {
        return (
          <span className="font-normal">
            {currencyFormatter(row['ppcSpend'])}
          </span>
        );
      },
      show: false,
      hideable: true,
      type: 'businessInformation',
    },
    {
      dataField: 'avgACOS',
      text: 'Avg. ACOS',
      sort: true,
      headerStyle: {
        minWidth: '180px',
      },
      formatter: (cell, row) => {
        return (
          <span className="font-normal">
            {percentageFormatter(row['avgACOS'])}
          </span>
        );
      },
      show: false,
      hideable: true,
      type: 'businessInformation',
    },
    // {
    //   dataField: 'AgencyClient.account.acknowledgement.acknowledgementId',
    //   text: 'Contract',
    //   sort: false,
    //   headerStyle: {
    //     minWidth: '180px',
    //   },
    //   formatter: (cell, row) => {
    //     const seenDate = row[`AgencyClient.account.acknowledgement.seenDate`];
    //     const signedDate =
    //       row[`AgencyClient.account.acknowledgement.signedDate`];
    //     return cell ? (
    //       <div className="flex flex-wrap">
    //         {seenDate && (
    //           <Badge color="green" classes="uppercase mr-2 my-1" rounded="md">
    //             <span
    //               data-tip={moment(seenDate, 'YYYY-MM-DD mm:ss').format('lll')}
    //             >
    //               viewed
    //             </span>
    //             <ReactTooltip
    //               place="top"
    //               className="max-w-xs text-black"
    //               backgroundColor="rgba(229, 231, 235, var(--tw-bg-opacity))"
    //               textColor="rgba(17, 24, 39, var(--tw-text-opacity))"
    //             />
    //           </Badge>
    //         )}
    //         {signedDate && (
    //           <Badge color="green" classes="uppercase mr-2 my-1" rounded="md">
    //             <span
    //               data-tip={moment(signedDate, 'YYYY-MM-DD mm:ss').format(
    //                 'lll'
    //               )}
    //             >
    //               signed
    //             </span>
    //             <ReactTooltip
    //               place="top"
    //               className="max-w-xs text-black"
    //               backgroundColor="rgba(229, 231, 235, var(--tw-bg-opacity))"
    //               textColor="rgba(17, 24, 39, var(--tw-text-opacity))"
    //             />
    //           </Badge>
    //         )}
    //       </div>
    //     ) : (
    //       ''
    //     );
    //   },
    //   show: params.status === 'Proposal',
    //   hideable: false,
    // },
    // {
    //   dataField: 'lead.dateOfCall',
    //   text: 'Call Booked',
    //   sort: true,
    //   headerStyle: {
    //     minWidth: '80px',
    //   },
    //   show: false,
    //   hideable: true,
    //   formatter: (cell, row) => {
    //     return (
    //       cell && (
    //         <span>
    //           {new Date(
    //             Date.UTC(
    //               new Date(cell).getFullYear(),
    //               new Date(cell).getMonth(),
    //               new Date(cell).getDate()
    //             )
    //           ) <
    //           new Date(
    //             Date.UTC(
    //               new Date().getFullYear(),
    //               new Date().getMonth(),
    //               new Date().getDate()
    //             )
    //           ) ? (
    //             <CalendarIcon className="h-6 w-6 text-gray-500" />
    //           ) : new Date(
    //               Date.UTC(
    //                 new Date(cell).getFullYear(),
    //                 new Date(cell).getMonth(),
    //                 new Date(cell).getDate()
    //               )
    //             ) <
    //             new Date(
    //               Date.UTC(
    //                 new Date().getFullYear(),
    //                 new Date().getMonth(),
    //                 new Date().getDate()
    //               )
    //             ).addDays(1) ? (
    //             <span className="text-red-600">Tomorrow</span>
    //           ) : (
    //             cell.slice(0, 10)
    //           )}
    //         </span>
    //       )
    //     );
    //   },
    // },
    // {
    //   dataField: 'action',
    //   text: 'Action',
    //   headerStyle: {
    //     minWidth: '80px',
    //   },
    //   show: userCan('sales.profiles.view'),
    //   className: 'text-center',
    //   formatter: (cell, row) => {
    //     return (
    //       <Link to={`/sales/client/${row.salesClientId}`} title="Profile">
    //         <UserCircleIcon className="m-1 h-5 w-5" color="green" />
    //       </Link>
    //     );
    //   },
    // },
  ];

  const dealFilters = [
    'All Deals',
    'Deals Im Collaborating With',
    'My Deals',
    'My Won Deals This Month',
    'New Deals This Month',
    'Deals Without Owners',
    'Lost Deals Today',
    'Todays Deals',
  ];
  const { user } = useLoggedInUser();

  function applyFilter(salesRepIds, date) {
    if (salesRepIds.length !== 0) {
      updateParams({ ...params, salesRepIds: salesRepIds });
    }

    if (date.length !== 0) {
      updateParams({ ...params, creationDate: date });
    }

    if (salesRepIds.length === 0 && date.length === 0) {
      updateParams({ ...params, salesRepIds: salesRepIds, creationDate: date });
    }
  }

  const [hoverSalesId, setHoverSalesId] = useState(null);
  const [hoverColumn, setHoverColumn] = useState('');
  const [tableColumns, setTableColumns] = useState(DEFAULT_COLUMNS);

  const handleCloseDateChange = (e, salesClientId) => {
    onUpdate(e, 'closedDealDate', salesClientId);
  };

  const fetchDealOwners = async () => {
    axios
      .get('/v2/users', {
        params: {
          filterGroups: JSON.stringify([
            {
              filters: [
                {
                  attribute: 'role.code',
                  operator: 'IN',
                  value: SALES_REPRESENTATIVE_ASSIGNEE.allowedRoles.map(
                    (role) => role.value
                  ),
                },
              ],
            },
          ]),
        },
      })
      .then((res) => {
        setDealOwners(res.data.data);
      })
      .catch(() => {
        setAlert('error', 'Failed to get deal owners');
      })
      .finally();
  };

  useEffect(() => {
    fetchDealOwners();
  }, [dispatch]);

  useEffect(() => {
    //check if user has own sales columns setting start
    if (user.settings && user.settings.salesColumn) {
      let columns = user.settings.salesColumn.map((c) => {
        let columnValue = null;
        DEFAULT_COLUMNS.forEach((element) => {
          if (element.dataField === c.dataField) {
            columnValue = {
              ...element,
              show: c.show,
            };
          }
        });
        return columnValue;
      });
      setTableColumns(columns);
    }
    //check if user has own sales columns setting end
  }, [user, hoverSalesId]);

  useEffect(() => {
    let isMounted = true;
    const filteredFields = [
      { value: 'status', label: 'Status' },
      { value: 'source', label: 'Source' },
      {
        value: 'salesRepId',
        isUser: true,
        foreignKey: 'salesRepId',
        as: 'salesRep',
        label: 'Sales Representative',
      },
    ];
    const fetchFieldOptions = async () => {
      const options = await axios.get(`/agency/sales/clients/distinct`, {
        params: {
          fields: filteredFields,
        },
        paramsSerializer: (params) => {
          return qs.stringify(params);
        },
      });
    };

    fetchFieldOptions().catch(console.error);

    updateParams({
      status: 'booked',
    });
    ReactTooltip.rebuild();
    return () => {
      isMounted = false;
    };
  }, []);

  const onChangeTab = (index) => {
    setSelectedIndex(index);
  };

  const onImport = async () => {
    setImporting(true);
    console.log('importing..');
    setImporting(false);
  };

  const checkRequiredFields = (val) => {
    let checkError = false;
    if (val['lead.lead'] === null || val['lead.lead'] === '') {
      dispatch(setAlert('error', 'Lead first name is required.'));
      checkError = true;
    }
    if (val['lead.leadLastName'] === null || val['lead.leadLastName'] === '') {
      dispatch(setAlert('error', 'Lead last name is required.'));
      checkError = true;
    }
    if (val['lead.email'] === null || val['lead.email'] === '') {
      dispatch(setAlert('error', 'Contact Details email is required.'));
      checkError = true;
    }
    if (val['decisionMaker'] === null || val['decisionMaker'] === '') {
      dispatch(setAlert('error', 'Decision maker is required.'));
      checkError = true;
    }
    if (val['email'] === null || val['email'] === '') {
      dispatch(setAlert('error', 'Business information email is required.'));
      checkError = true;
    }
    return checkError;
  };

  //check required fields when change status to Proposal end
  const onUpdate = async (val, prop, salesClientId, row = {}) => {
    if (val === 'Proposal') {
      const isError = checkRequiredFields(row);
      if (isError) {
        return;
      }
    }

    try {
      const response = await axios.put(
        `/agency/sales/clients/${salesClientId}`,
        {
          [prop]: val,
        }
      );
      if (response.data.success) {
        dispatch(fetchSalesClients({ ...params, ...searchParams }));
        dispatch(setAlert('success', 'Updated Successfully'));
      }
    } catch (error) {
      console.log(error);
    }
  };

  const onUpdateDealAmount = async (e, val, salesClientId) => {
    if (e.key === 'Enter') {
      try {
        const response = await axios.put(
          `/agency/sales/clients/${salesClientId}`,
          {
            ['totalAmountPaid']: val,
          }
        );
        if (response.data.success) {
          dispatch(fetchSalesClients({ ...params, ...searchParams }));
          dispatch(setAlert('success', 'Updated Successfully'));
        }
      } catch (error) {
        console.log(error);
      }
    } else if (e.key === 'Escape') {
      setHoverColumn('');
      setHoverSalesId(null);
    }
  };

  return (
    <div className="flex flex-col h-full" style={{ maxHeight: '86vh' }}>
      <div className="sm:grid grid-cols-2 mb-4">
        <PageHeader
          title={'Deals'}
          left={
            <>
              <div className=" flex">
                <div className="flex justify-end">
                  {['list', 'board'].map((category, index) => {
                    return (
                      <button
                        type="button"
                        onClick={() => onChangeTab(index)}
                        key={category}
                        className={classNames(
                          'px-2 py-1 text-sm font-medium leading-5 border-2 border-gray-600',
                          'ring-white ring-offset-2 ring-offset-white',
                          index === 0 ? ' rounded-l-lg' : ' rounded-r-lg',
                          selectedIndex === index
                            ? 'bg-gray-600 shadow text-white'
                            : 'text-gray-600 hover:bg-white/[0.12] bg-white'
                        )}
                      >
                        {category === 'list' ? (
                          <MenuIcon className="h-5 w-5" />
                        ) : (
                          <ViewGridIcon className="h-5 w-5" />
                        )}
                      </button>
                    );
                  })}
                  <select
                    id="allDeals"
                    className="rounded-xl border-0 focus:ring-0 appearance-none focus:appearance-none focus:border-0 sm:text-sm ml-4 disabled:bg-gray-100"
                    onChange={(e) => {
                      setSearchParams((prev) => ({
                        ...prev,
                        dealType: e.target.value,
                      }));
                    }}
                  >
                    <option value="">Standard</option>
                    {dealFilters.map((deal, i) => (
                      <option key={i} value={deal}>
                        {deal}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </>
          }
          border=""
          containerClasses=""
        />
        <div className="flex justify-end items-center mb-6">
          <Button
            classes={'font-bold tracking-widest ml-5 mt-5'}
            border=" border-2 border-blue-900 tracking-widest font-bold"
            hoverColor="blue-100"
            textColor="blue-900"
            bgColor="bg-white"
            roundedSize="full"
            px={14}
            py={2}
            shadow=""
            textSize="sm"
            showLoading={true}
            loading={importing}
            onClick={async () => {
              await onImport();
            }}
            disabled={!userCan('sales.deals.import')}
          >
            Import
          </Button>
          <Link to="/sales/client/add">
            <Button
              classes={'border-0 font-bold tracking-widest ml-5 mt-5'}
              bgColor="blue-900"
              hoverColor="blue-500"
              roundedSize="full"
              textColor="white"
              px={14}
              py={2}
              shadow=""
              textSize="sm"
              showLoading={true}
              loading={loading}
              disabled={!userCan('sales.deals.create')}
            >
              Create
            </Button>
          </Link>
        </div>
      </div>

      <div className="pb-4">
        <BoardViewSearchField
          setSearchParams={setSearchParams}
          dealOwners={dealOwners}
        />
      </div>

      {selectedIndex === 0 && (
        <CustomizeColumnBtn
          tableColumns={tableColumns}
          setTableColumns={setTableColumns}
        />
      )}

      {selectedIndex === 0 ? (
        <ListView tableColumns={tableColumns} searchParams={searchParams} />
      ) : (
        <BoardView searchParams={searchParams} />
      )}
    </div>
  );
};

export default Overview;
