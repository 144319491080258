import PageHeader from 'components/PageHeader';

import React, { useEffect, useState } from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Chip from '@material-ui/core/Chip';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import moment from 'moment';
import CreatePitchTemplate from './Modal/CreatePitchTemplate';
import Actions from './actions/Actions';
import axios from 'axios';
import Loading from 'components/Loading';
import { setAlert } from 'features/alerts/alertsSlice';
import { useDispatch } from 'react-redux';
import Pagination from './pitchTemplates/Pagination';

const PitchTemplates = ({ tabs }) => {
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const toggleModal = () => {
    setOpen(!open);
  };
  const dispatch = useDispatch();
  const route = '/agency/leads';

  const [editMode, setEditMode] = useState(false);

  const editModeHandler = (flag) => {
    setEditMode(flag);
  };

  const [pitchData, setPitchData] = useState([]);
  const [editPitchData, setEditPitchData] = useState();
  const [page, setPage] = useState(1);
  const [paginationData, setPaginationData] = useState();

  const fetchPitchData = (pageNumber) => {
    setIsLoading(true);
    axios
      .get(`${route}/pitch/template/${pageNumber ? pageNumber : page}`)
      .then((response) => {
        const { data } = response?.data;
        setPitchData(data?.rows);
        setPaginationData(data);
        setIsLoading(false);
        if (!data.nextPage) {
          setPage(1);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        console.error(err);
      });
  };

  const deletePitchTemplate = (id) => {
    setIsLoading(true);
    axios
      .delete(`${route}/pitch/template/${id}`)
      .then((response) => {
        if (response) {
          fetchPitchData();
          dispatch(
            setAlert(
              'success',
              `Your template changes has been saved`,
              '',
              8000,
              '',
              true
            )
          );
        }
      })
      .catch((err) => {
        setIsLoading(false);
        console.error(err);
      });
  };

  const deActivateAction = (id, type) => {
    setIsLoading(true);
    const editPayload = {
      id: id,
      payload: {
        status: type === 'Deactivate' ? 'Active' : 'Deactivate',
      },
    };

    axios
      .put(`${route}/pitch/template`, editPayload)
      .then((response) => {
        if (response) {
          fetchPitchData();
          dispatch(
            setAlert(
              'success',
              `Your template changes has been saved`,
              '',
              8000,
              '',
              true
            )
          );
        }
      })
      .catch((err) => {
        setIsLoading(false);
        console.error(err);
      });
  };

  const createPitchTemplate = (payload, setSubmitting) => {
    if (editMode) {
      const editPayload = {
        id: editPitchData?.pitchTemplateId,
        payload,
      };

      axios
        .put(`${route}/pitch/template`, editPayload)
        .then((response) => {
          if (response) {
            fetchPitchData();
            setOpen(false);
            dispatch(
              setAlert(
                'success',
                `Your template changes has been saved`,
                '',
                8000,
                '',
                true
              )
            );
          }
        })
        .catch((err) => {
          console.error(err);
          setSubmitting(false);
        });
    } else {
      axios
        .post(`${route}/pitch/template`, payload)
        .then((response) => {
          fetchPitchData();
          dispatch(
            setAlert(
              'success',
              `Your template changes has been saved`,
              '',
              8000,
              '',
              true
            )
          );
          setOpen(false);
        })
        .catch((err) => {
          console.error(err);
          setSubmitting(false);
          if (err.response.data.errors?.value) {
            dispatch(
              setAlert('error', `Description is required`, '', 8000, '', true)
            );
          }
          if (err.response.data.errors?.key) {
            dispatch(
              setAlert('error', `Template name is required`, '', 8000, '', true)
            );
          }
        });
    }
  };

  const editModal = (id) => {
    const dataById = pitchData.find((data) => data.pitchTemplateId === id);
    setEditPitchData(dataById);
    setOpen(true);
    setEditMode(true);
  };

  useEffect(() => {
    fetchPitchData();
  }, []);

  const getStatusColor = (status) => {
    let color = '#bebebe';

    if (status?.toLowerCase() === 'active') {
      color = '#3dd7ae';
    } else if (status?.toLowerCase() === 'inactive') {
      color = '#bebebe';
    } else {
      color = '#ff6868';
    }

    return color;
  };

  const paginationHandler = (page, action) => {
    if (action === 'next') {
      setPage(Number(page) + 1);
      fetchPitchData(Number(page) + 1);
    } else {
      setPage(Number(page) - 1);
      fetchPitchData(Number(page) - 1);
    }
  };

  return (
    <>
      <PageHeader title="" tabs={tabs} containerClasses={''} />
      {isLoading && (
        <div className="flex text-center justify-center mt-4">
          <Loading />
        </div>
      )}
      {!isLoading && (
        <>
          <div className="flex mt-4 justify-items-center items-center justify-between">
            <div>
              <h2 className="flex-1 text-lg font-bold leading-3 text-gray-900 sm:text-3xl sm:truncate py-5 border-b-2 border-transparent">
                Pitch Templates
              </h2>
            </div>

            <div>
              <button
                className="bg-secondary hover:bg-blue-400 text-white py-2 px-4 rounded-full w-32"
                onClick={() => {
                  toggleModal();
                  editModeHandler(false);
                }}
              >
                Create
              </button>
            </div>
          </div>
          <div className="grid grid-cols-1 sm:grid-cols-3 gap-4 my-4">
            {pitchData?.map((data) => (
              <div className="grid grid-cols-1 ">
                <Card>
                  <div className="flex ml-5 mt-8 justify-items-center items-center">
                    <FiberManualRecordIcon
                      style={{
                        color: getStatusColor(data.status),
                        fontSize: '17px',
                      }}
                    />
                    <Typography
                      variant="subtitle1"
                      variantMapping={{ subtitle1: 'p' }}
                      style={{
                        fontSize: '13px !important',
                        marginLeft: '0.6rem',
                      }}
                    >
                      {data.status}
                    </Typography>
                  </div>
                  <Actions
                    title={data.key}
                    status={data?.status}
                    deActivateAction={deActivateAction}
                    id={data.pitchTemplateId}
                    editPitchTemplateModal={editModal}
                    deleteAction={deletePitchTemplate}
                  />
                  <CardContent className="flex flex-col h-48 pt-0">
                    <div
                      style={{
                        overflowY: 'auto',
                        maxHeight: '120px',
                      }}
                    >
                      {data?.deficiencies?.map((deficiency) => (
                        <>
                          {deficiency && (
                            <div className="mb-auto">
                              <Chip
                                label={deficiency}
                                style={{
                                  backgroundColor: '#d2e4f6',
                                  color: '#001c37',
                                }}
                                className="mt-2 ml-3"
                              />
                            </div>
                          )}
                        </>
                      ))}
                    </div>
                    <div className="mt-auto ml-2">
                      <Typography
                        titleTypographyProps={{ variant: 'p' }}
                        className="flex mt-3 text-10"
                        style={{ color: '#666666', fontSize: '13px' }}
                      >
                        Last edited{' '}
                        {moment(data.createdAt).format('MMMM Do h:mm:ss a')}
                      </Typography>
                    </div>
                  </CardContent>
                </Card>
              </div>
            ))}
          </div>
          <Pagination
            paginationData={paginationData}
            paginationHandler={paginationHandler}
          />
        </>
      )}
      <CreatePitchTemplate
        open={open}
        toggleModal={toggleModal}
        submitHandler={createPitchTemplate}
        data={editPitchData}
        editMode={editMode}
      />
    </>
  );
};

export default PitchTemplates;
