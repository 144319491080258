import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

export const fetchDefaultSignature = createAsyncThunk(
  'mail/defaultSignature',
  async (userId) => {
    const response = await axios.get(`/agency/email-signature/default`);
    return response.data.data;
  }
);

export const mailSlice = createSlice({
  name: 'mail',
  initialState: {
    action: '',
    id: '',
    type: '',
    loading: false,
    defaultSignature: null,
    draftMail: null,
    mailSubject: null
  },
  reducers: {
    setAction: (state, action) => {
      state.action = action.payload;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setId: (state, action) => {
      state.id = action.payload;
    },
    setType: (state, action) => {
      state.type = action.payload;
    },
    setData: (state, { payload }) => {
      state.action = payload.action;
      state.id = payload.id;
      state.type = payload.type;
    },
    setDefaultSignature: (state, action) => {
      state.defaultSignature = action.payload;
    },
    setDraftMail: (state, action) => {
      state.draftMail = action.payload;
    },
    setMailSubject: (state, action) => {
      state.mailSubject = action.payload;
    },
  },
  extraReducers: {
    [fetchDefaultSignature.fulfilled]: (state, { payload }) => {
      state.defaultSignature = payload;
    },
  },
});

export const {
  setAction,
  setId,
  setType,
  setLoading,
  setData,
  setDefaultSignature,
  setDraftMail,
  setMailSubject,
} = mailSlice.actions;

export default mailSlice.reducer;
