import axios from 'axios';
import Button from 'components/Button';
import HtmlEditor from 'components/Editor/HtmlEditor';
import Input from 'components/Forms/Input';
import Label from 'components/Forms/Label';
import RequiredAsterisk from 'components/Forms/RequiredAsterisk';
import Modal from 'components/Modal';
import ModalHeader from 'components/ModalHeader';
import useAlert from 'hooks/useAlert';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import 'react-quill/dist/quill.snow.css';
import ButtonLink from 'components/ButtonLink';

const EmailInvoiceModal = ({ invoice, open, setOpen, type = '' }) => {
  const { alertSuccess, alertError } = useAlert();
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    to: '',
    cc: '',
    subject: '',
    body: '',
  });

  useEffect(() => {
    if (invoice && open) {
      let subject = '';
      let insertMessage = '';

      switch (type) {
        case 'overdue':
          subject = `Overdue follow-up from Seller Interactive (Invoice#: ${invoice.number})`;
          insertMessage = 'The following invoice is overdue.<br /><br />';
          break;
        case 'pastDue':
          subject = `Past Due Date follow-up from Seller Interactive (Invoice#: ${invoice.number})`;
          insertMessage =
            'The following invoice has is past due date.<br /><br />';
          break;
        case 'invoiceReminder':
          subject = `Invoice reminder from Seller Interactive (Invoice#: ${invoice.number})`;
          insertMessage =
            'This email is to remind you of your pending invoice.<br /><br />';
          break;
        default:
          subject = `Invoice from Seller Interactive (Invoice#: ${invoice.number})`;
          insertMessage = '';
          break;
      }

      setFormData({
        ...formData,
        to: invoice.contactpersons[0].email,
        subject,
        body: `Hi ${
          invoice.customer_name
        },<br /><br />${insertMessage}Please review this invoice and let us know if you have any questions or concerns.<br />${
          invoice.can_skip_payment_info === false
            ? `You can pay for the invoice in Better Seller. You can also view, print or download the invoice.<br /><br /><a href="${process.env.REACT_APP_CLIENT_URL}">LOGIN</a>`
            : 'We will charge the credit card on file in 4 days.'
        }<br/><br/>Thanks for your business,<br/>
        Seller Interactive`,
      });
    }
  }, [invoice, open, type]);

  const onInputChange = (e) => {
    const { target } = e;
    setFormData({ ...formData, [target.name]: target.value });
  };

  const handleChange = (content, delta, source, editor) => {
    setFormData({ ...formData, body: content });
  };

  const onSubmit = async () => {
    const to = formData.to.split(',').map((a) => a.trim());
    const cc = formData.cc ? formData.cc.split(',').map((a) => a.trim()) : [];
    const payload = { ...formData, to, cc };

    setLoading(true);

    try {
      const res = await axios.post(
        `agency/invoices/${invoice.invoice_id}/email`,
        payload
      );

      alertSuccess('success', res.data.data.message);
    } catch (error) {
      alertError('error', error.response.data.message);
    } finally {
      setLoading(false);
      setOpen(false);
    }
  };

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      align="top"
      as={'div'}
      noOverlayClick={true}
    >
      <div className="inline-block w-full max-w-xl my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-xl">
        <ModalHeader
          title="Email Invoice"
          setOpen={setOpen}
          titleClasses="capitalize"
          border=""
          fontSize="text-xl"
          fontStyle="font-bold"
          px="px-4 md:px-8"
          py="py-4 md:py-8"
        />

        <form method="POST" onSubmit={onSubmit}>
          <div className="pb-4 md:pb-8 px-4 md:px-8">
            <div className="">
              <div className="flex justify-between">
                <Label htmlFor="to" classes="text-left">
                  To <RequiredAsterisk />
                </Label>
                <span className="text-1xs text-red-400">
                  separate emails by comma
                </span>
              </div>

              <Input
                id="to"
                type="text"
                value={formData.to}
                onChange={onInputChange}
                required
                className="form-input"
              />
            </div>
            <div className="mt-2">
              <Label htmlFor="cc" classes="text-left">
                Cc
              </Label>
              <Input
                id="cc"
                type="text"
                value={formData.cc}
                onChange={onInputChange}
                className="form-input"
                required
              />
            </div>
            <div className="mt-2">
              <Label htmlFor="subject" classes="text-left">
                Subject <RequiredAsterisk />
              </Label>
              <Input
                id="subject"
                type="text"
                value={formData.subject}
                onChange={onInputChange}
                className="form-input"
                required
              />
            </div>
            <div className="mt-2">
              <Label htmlFor="body" classes="text-left">
                Body <RequiredAsterisk />
              </Label>
              <HtmlEditor value={formData.body} onChange={handleChange} />
            </div>
          </div>
          <div className="space-x-4 pb-4 md:pb-8 px-4 md:px-8">
            <ButtonLink
              classes="tracking-wider font-bold"
              color="red"
              onClick={() => setOpen(false)}
            >
              Cancel
            </ButtonLink>
            <Button
              classes="border-0 font-bold tracking-wider "
              bgColor="red-700"
              hoverColor="red-400"
              roundedSize="2xl"
              textColor="white"
              px={12}
              py={1.5}
              shadow=""
              onClick={onSubmit}
              showLoading={true}
              loading={loading}
            >
              Send
            </Button>
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default EmailInvoiceModal;
