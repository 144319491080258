import React, { useState } from 'react';
import axios from 'axios';
import { PlusIcon } from '@heroicons/react/solid';
import usePermissions from 'hooks/usePermissions';
import useAlert from 'hooks/useAlert';
import { dateFormat } from 'utils/formatters';
import { DateTime } from 'luxon';

const Export = ({ params = {}, url, fileName = 'export' }) => {
  const { isAgencySuperUser } = usePermissions();
  const [loading, setLoading] = useState(false);

  const { alertSuccess, alertError } = useAlert();

  const convertCsv = async () => {
    setLoading(true);
    try {
      const response = await axios.get(url, { params });
      const blob = new Blob([response.data], {
        type: response.headers['content-type'],
        encoding: 'UTF-8',
      });

      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = `${fileName}_${DateTime.now().toFormat('yyLLdd')}.csv`;
      link.click();

      alertSuccess('Export Successful', 'Downloading csv...');
    } catch (error) {
      alertError('Failed to export', error.response.data.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="sm:col-span-2">
      {isAgencySuperUser() && (
        <button
          type="button"
          onClick={convertCsv}
          className="px-4 shadow-sm text-sm text-blue-900 bg-bg-white hover:bg-blue-100 focus:ring-red-500  border-2 border-blue-900 tracking-widest font-bold py-1.5 rounded-full inline-flex items-center focus:outline-none"
        >
          <PlusIcon className="h-4 w-4 mr-2" /> Export
        </button>
      )}
    </div>
  );
};

export default Export;
