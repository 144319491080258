import { configureStore } from '@reduxjs/toolkit';
import authReducer from '../features/auth/authSlice';
import alertsReducer from '../features/alerts/alertsSlice';
import agencyClientsReducer from '../features/clients/agencyClientsSlice';
import clientsReducer from '../features/clients/clientsSlice';
import surveysReducer from 'features/clients/Survey/surveySlice';
import employeesReducer from '../features/employees/employeesSlice';
import invoicesReducer from '../features/invoices/invoicesSlice';
import accountsReducer from '../features/accounts/accountsSlice';
import advertisingReducer from 'features/advertising/advertisingSlice';
import datePickerReducer from '../features/datePicker/datePickerSlice';
import ordersReducer from 'features/orders/ordersSlice';
import notesReducer from 'features/notes/notesSlice';
import reviewsReducer from '../features/reviews/reviewsSlice';
import productAlertReducer from 'features/productAlerts/productAlertSlice';
import onboardingReducer from 'features/onboarding/onboardingSlice';
import creditNotesReducer from 'features/creditNotes/creditNotesSlice';
import advertisingOptimizationReducer from 'features/advertising/optimizations/optimizationSlice';
import upsellsReducer from 'features/upsells/upsellsSlice';
import leadsReducer from 'features/leads/leadsSlice';
import notificationsReducer from 'features/notification/notificationSlice';
import salesReducer from 'features/sales/salesSlice';
import tasksReducer from 'features/tasks/tasksSlice';
import agencyClientReducer from 'features/clients/Client/agencyClientSlice';
import zohoReducer from 'features/zoho/zohoSlice';
import marketplacesReducer from 'features/marketplaces/marketplacesSlice';
import subscriptionReducer from 'features/clients/Client/Billing/subscriptionSlice';
import accountReducer from 'features/clients/Client/Account/accountSlice';
import invoiceReducer from '../features/invoices/Invoice/invoiceSlice';
import writingReducer from 'features/writing/writingSlice';
import designReducer from 'features/design/designSlice';
import organizationReducer from 'features/organization/organizationSlice';
import usersReducer from 'features/users/usersSlice';
import mockupReducer from 'features/clients/Client/Dashboard/Mockup/mockupSlice';
import settingsReducer from 'features/settings/settingsSlice';
import mailReducer from 'components/Editor/components/mailSlice';
import commentsReducer from 'components/Editor/components/commentSlice';
import billingReducer from 'features/billing/billingSlice';
import complaintReducer from 'features/complaint/complaintSlice';
import countryReducer from 'features/country/countrySlice';
import rolesReducer from 'features/roles/rolesSlice';
import tasksReducer2 from 'Version2/features/tasks/tasksSlice';

export default configureStore({
  reducer: {
    auth: authReducer,
    account: accountReducer,
    accounts: accountsReducer,
    advertising: advertisingReducer,
    advertisingOptimization: advertisingOptimizationReducer,
    agencyClient: agencyClientReducer,
    agencyClients: agencyClientsReducer,
    alerts: alertsReducer,
    billing: billingReducer,
    comment: commentsReducer,
    complaint: complaintReducer,
    countries: countryReducer,
    clients: clientsReducer,
    creditNotes: creditNotesReducer,
    datePicker: datePickerReducer,
    design: designReducer,
    employees: employeesReducer,
    invoice: invoiceReducer,
    invoices: invoicesReducer,
    leads: leadsReducer,
    mail: mailReducer,
    marketplaces: marketplacesReducer,
    mockup: mockupReducer,
    notes: notesReducer,
    notifications: notificationsReducer,
    onboarding: onboardingReducer,
    orders: ordersReducer,
    organization: organizationReducer,
    productAlerts: productAlertReducer,
    reviews: reviewsReducer,
    sales: salesReducer,
    settings: settingsReducer,
    surveys: surveysReducer,
    subscription: subscriptionReducer,
    tasks: tasksReducer,
    upsells: upsellsReducer,
    users: usersReducer,
    writing: writingReducer,
    zoho: zohoReducer,
    roles: rolesReducer,
    'tasks.v2': tasksReducer2,
  },
});
