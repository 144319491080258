import ClientList from './components/ClientList';

const Active = () => {
  return (
    <ClientList
      title="Active Clients"
      initialFilters={[
        {
          attribute: 'isOnboarding',
          operator: 'EQ',
          value: false,
        },
        {
          attribute: 'status',
          operator: 'EQ',
          value: 'ACTIVE',
        },
      ]}
      excludeColumns={[
        'agencyClientId',
        'terminatedAt',
        'invoiceSummary',
        'termination.createdAt',
        'termination.requestor.firstName',
        'termination.evaluator.firstName',
        'terminationReason',
        'totalPaidInvoiceAmount',
        'totalUnPaidInvoiceAmount',
      ]}
      defaultColumns={[
        'client',
        'status',
        'service',
        'subscription',
        'projectManager',
        'brandStrategist',
        'accountSpecialist',
        'contractSigned',
        'hasInvalidAssignee',
      ]}
      initialParams={{
        page: 1,
        pageSize: 10,
        sorts: 'client:asc',
        search: '',
        filters: '[]',
        include: [
          'subscription',
          'defaultContact',
          'projectManager[user[avatar]]',
          'brandStrategist[user[avatar]]',
          'accountSpecialist[user[avatar]]',
        ],
        attributes: [
          'phone',
          'client',
          'status',
          'service',
          'address',
          'website',
          'accountStatus',
          'amazonPageUrl',
          'agencyClientId',
          'draftMarketplace',
          'latestComplaintStatus',
          'isSPAPIConnected',
          'isADVAPIConnected',
          'contractSigned',
          'salesClientId',
          'subscriptionPaymentOption',
          'hasInvalidAssignee',
        ],
      }}
    />
  );
};

export default Active;
