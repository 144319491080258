import React from 'react';
import AgencyLayout from 'layouts/agency/AgencyLayout';
import PrivateRoute from './PrivateRoute';
import Employees from 'features/employees/Employees';
import Churn from 'features/churn/Churn';
import DataSyncManager from 'features/dataSync/DataSyncManager';
import Reports from 'features/reports/Reports';
import Settings from 'features/settings/Settings';
import TaskManagement from 'features/taskManagement/TaskManagement';
import ProgressReport from 'features/progressReport/ProgressReport';
import Partners from 'features/partners/Partners';
import Surveys from 'features/clients/Survey/Surveys';

const AdminRoutes = [
  <PrivateRoute
    path="/employees"
    layout={AgencyLayout}
    component={Employees}
    access={['agency']}
  />,

  <PrivateRoute
    path="/churn"
    layout={AgencyLayout}
    component={Churn}
    access={['agency']}
  />,

  <PrivateRoute
    path="/data-sync"
    layout={AgencyLayout}
    component={DataSyncManager}
    access={['agency']}
  />,

  <PrivateRoute
    path="/reports"
    component={Reports}
    layout={AgencyLayout}
    access={['agency']}
  />,

  <PrivateRoute
    path="/partners"
    component={Partners}
    layout={AgencyLayout}
    access={['agency']}
  />,

  <PrivateRoute
    path="/system-settings"
    component={Settings}
    layout={AgencyLayout}
    access={['agency']}
  />,

  <PrivateRoute
    path="/employee-surveys"
    component={Surveys}
    layout={AgencyLayout}
    access={['agency']}
  />,

  <PrivateRoute
    path="/task-management"
    component={TaskManagement}
    layout={AgencyLayout}
    access={['agency']}
  />,

  <PrivateRoute
    path="/progress-report"
    component={ProgressReport}
    layout={AgencyLayout}
    access={['agency']}
  />,

  <PrivateRoute
    path="/employee-surveys"
    component={Surveys}
    layout={AgencyLayout}
    access={['agency']}
  />,
];
export default AdminRoutes;
