import React, { useState } from 'react';
import axios from 'axios';
import { PlusIcon } from '@heroicons/react/solid';
import Button from 'components/Button';
import usePermissions from 'hooks/usePermissions';
import DateRangePickerNew from 'components/DateRangePickerNew';
import useAlert from 'hooks/useAlert';
import { dateFormat } from 'utils/formatters';
import { Popover } from '@headlessui/react';
import { usePopper } from 'react-popper';

const ExportByDate = ({ params = {}, url, fileName = 'export' }) => {
  const { isAgencySuperUser } = usePermissions();
  const [loading, setLoading] = useState(false);
  const [selectedRange, setSelectedRange] = useState({ from: null, to: null });
  const { alertSuccess, alertError } = useAlert();

  let [referenceElement, setReferenceElement] = useState();
  let [popperElement, setPopperElement] = useState();
  let { styles, attributes } = usePopper(referenceElement, popperElement, {
    modifiers: [
      {
        name: 'offset',
        options: { offset: [-150, 0] },
      },
    ],
  });

  const convertCsv = async (close) => {
    setLoading(true);
    try {
      const response = await axios.get(url, {
        params: {
          ...params,
          startDate: dateFormat(selectedRange.from),
          endDate: dateFormat(selectedRange.to),
        },
      });
      const blob = new Blob([response.data], {
        type: response.headers['content-type'],
        encoding: 'UTF-8',
      });

      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = `${fileName}_${dateFormat(
        selectedRange.from,
        'yyLLdd'
      )}-${dateFormat(selectedRange.to, 'yyLLdd')}.csv`;
      link.click();

      alertSuccess('Export Successful', 'Downloading csv...');
      close();
    } catch (error) {
      alertError('Failed to export', error.response.data.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="sm:col-span-2">
      {isAgencySuperUser() && (
        <Popover>
          <Popover.Button
            ref={setReferenceElement}
            className="px-4 shadow-sm text-sm text-blue-900 bg-bg-white hover:bg-blue-100 focus:ring-red-500  border-2 border-blue-900 tracking-widest font-bold py-1.5 rounded-full inline-flex items-center focus:outline-none"
          >
            <PlusIcon className="h-4 w-4 mr-2" /> Export by Date
          </Popover.Button>
          <Popover.Panel
            ref={setPopperElement}
            style={styles.popper}
            {...attributes.popper}
            className="z-20 bg-white shadow-md py-4 rounded-lg"
          >
            {({ close }) => (
              <>
                <DateRangePickerNew
                  onChangeDates={(from, to) => setSelectedRange({ from, to })}
                />

                <div className="text-right px-5">
                  <Button
                    classes="border-0 font-bold tracking-widest disabled:opacity-30"
                    bgColor="red-600"
                    hoverColor="red-700"
                    roundedSize="full"
                    textColor="white"
                    px={4}
                    py={1}
                    shadow=""
                    type="button"
                    loading={loading}
                    onClick={() => convertCsv(close)}
                    showLoading={true}
                    disabled={!selectedRange.from || !selectedRange.to}
                  >
                    Export
                  </Button>
                </div>
              </>
            )}
          </Popover.Panel>
        </Popover>
      )}
    </div>
  );
};

export default ExportByDate;
