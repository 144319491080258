import ReactTooltip from 'react-tooltip';
import { ExclamationCircleIcon } from '@heroicons/react/outline';

import { UserSelector } from 'components';
import useClientAssignees from 'features/clients/Client/useClientAssignees';

const ClientAssigneeItem = ({
  item,
  clientId,
  assignee,
  params = {},
  className = '',
  disabled = false,
}) => {
  const { updateAssignee, deleteAssignee } = useClientAssignees();

  return (
    <div className={className} key={item.role.value}>
      <UserSelector
        params={params}
        disabled={disabled}
        value={assignee?.user}
        onChange={({ userId }) =>
          updateAssignee(clientId, userId, item.role.value)
        }
        onDelete={({ userId }) => deleteAssignee(clientId, userId)}
      />

      <div className="mt-1.5">
        {assignee && !assignee.isValid && (
          <div
            className="flex justify-center"
            title="Client assignee is invalid."
          >
            <ExclamationCircleIcon
              className="w-5 h-5 text-orange-500"
              data-tip
              data-for={`invalid-${assignee.user?.userId}`}
            />

            <ReactTooltip
              id={`invalid-${assignee.user?.userId}`}
              place="bottom"
              effect="solid"
              backgroundColor="rgba(229, 231, 235, var(--tw-bg-opacity))"
              textColor="rgba(17, 24, 39, var(--tw-text-opacity))"
              padding="0 10px"
            >
              Client Assignee is invalid.
            </ReactTooltip>
          </div>
        )}

        <p className="text-11 md:text-13 leading-1.5 text-grayscale-700 text-center px-2">
          {item.label ?? item.role.name}
        </p>
      </div>
    </div>
  );
};

export default ClientAssigneeItem;
