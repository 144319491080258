import Label from 'components/Forms/Label';
import { cloneDeep, debounce } from 'lodash';
import { FieldArray } from 'formik';
import React, { useState } from 'react';

const DeficiencyList = ({ deficiencyItems, resetHandler, values }) => {
  const [isShowListing, setIsShowListing] = useState(false);
  const [filteredDeficiencyItems, setFilteredDeficiencyItems] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');

  const isShowListingHandler = () => {
    setIsShowListing(!isShowListing);
  };

  const searchListingDeficiency = (value) => {
    setSearchTerm(value);
    const clonedItems = cloneDeep(deficiencyItems);
    if (value.length <= 0) {
      resetHandler();
      setFilteredDeficiencyItems([]);
    } else {
      const filteredItems = clonedItems.filter(
        (item) => item.title.toLowerCase().indexOf(value) > -1
      );
      setFilteredDeficiencyItems(filteredItems);
    }
  };

  return (
    <div className="flex flex-row">
      <Label>Listing Deficiency Mark</Label>
      <div
        className="bg-white ml-6 rounded-xl"
        style={{
          minWidth: '340px',
        }}
      >
        <div
          className="flex cursor-pointer p-2 min-w-full justify-between"
          onClick={isShowListingHandler}
        >
          <p className="text-13">
            {values.deficiencies.length === 0
              ? 'Select Listing Deficiency Mark'
              : `Selected ${values.deficiencies.length}`}{' '}
          </p>
          <div className="ml-3">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth={2}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M19 9l-7 7-7-7"
              />
            </svg>
          </div>
        </div>
        {isShowListing && (
          <>
            <hr className="mt-1 mb-1" />
            <div
              className="bg-white mt-1 p-4"
              style={{
                maxHeight: '200px',
                overflowY: 'auto',
              }}
            >
              <label
                htmlFor="search"
                className="relative text-gray-400 focus-within:text-gray-600 block"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5 absolute pointer-events-none top-1/2 transform -translate-y-1/2 left-3"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth={2}
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                  />
                </svg>
                <input
                  type="text"
                  placeholder="Search"
                  onChange={debounce((e) => {
                    searchListingDeficiency(e.target.value);
                  }, 1000)}
                  className="w-full pl-10 placeholder-gray-300 text-gray-500 border-none rounded-xl outline-none bg-gray-50 focus:border-white"
                />
              </label>

              <div className="mt-3">
                {searchTerm?.length === 0 && (
                  <FieldArray
                    name="deficiencies"
                    render={({ push, remove }) => {
                      {
                        return deficiencyItems?.map((item, index) => (
                          <div
                            className="flex items-center mt-3"
                            style={{
                              maxWidth: '340px',
                            }}
                            key={index}
                          >
                            <label
                              htmlFor={item?.id}
                              className="cursor-pointer"
                            >
                              <input
                                id={item?.id}
                                name="deficiencies"
                                type="checkbox"
                                value={item.title}
                                checked={values?.deficiencies
                                  .map((e) => e)
                                  .includes(item.title)}
                                onChange={(e) => {
                                  if (e.target.checked) {
                                    push(item.title);
                                  } else {
                                    const index = values?.deficiencies
                                      .map(function (e) {
                                        return e;
                                      })
                                      .indexOf(item.title);
                                    remove(index);
                                  }
                                }}
                              />
                              <span className="ml-2">{item.title}</span>
                            </label>
                          </div>
                        ));
                      }
                    }}
                  />
                )}
                {searchTerm?.length > 0 && (
                  <FieldArray
                    name="deficiencies"
                    render={({ push, remove }) => {
                      {
                        return filteredDeficiencyItems?.map((item, index) => (
                          <div
                            className="flex items-center mt-3"
                            style={{
                              maxWidth: '340px',
                            }}
                          >
                            <label
                              htmlFor={item?.id}
                              className="cursor-pointer"
                            >
                              <input
                                id={item?.id}
                                name="deficiencies"
                                type="checkbox"
                                value={item.title}
                                checked={values.deficiencies
                                  .map((e) => e)
                                  .includes(item.title)}
                                onChange={(e) => {
                                  if (e.target.checked) {
                                    push(item.title);
                                  } else {
                                    const index = values.deficiencies
                                      .map(function (e) {
                                        return e;
                                      })
                                      .indexOf(item.title);
                                    remove(index);
                                  }
                                }}
                              />
                              <span className="ml-2">{item.title}</span>
                            </label>
                          </div>
                        ));
                      }
                    }}
                  />
                )}
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default DeficiencyList;
