import { Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';

const Modal = ({
  open,
  setOpen,
  children,
  align = 'center',
  noOverlayClick = false,
  as = null,
  persistent,
  showOverlay = true,
  zIndex = 'z-40',
  isBlock = false,
}) => {
  return (
    <Transition.Root show={open} as={as ?? Fragment}>
      <Dialog
        as="div"
        static
        className={`fixed ${zIndex} inset-0 overflow-y-auto`}
        open={open}
        onClose={persistent ? () => {} : setOpen}
      >
        <div
          className={`flex items-end justify-center pt-4 px-4 pb-20 text-center ${
            isBlock ? '' : 'sm:block min-h-screen'
          }  sm:p-0`}
        >
          <Transition.Child
            as={as ?? Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            {showOverlay && (
              <Dialog.Overlay
                className={`fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity ${
                  noOverlayClick ? 'pointer-events-none' : ''
                }`}
              />
            )}
          </Transition.Child>

          {align === 'center' && (
            <span
              className={`hidden sm:inline-block sm:align-middle ${
                isBlock ? '' : 'sm:h-screen'
              }`}
              aria-hidden="true"
            >
              &#8203;
            </span>
          )}

          <Transition.Child
            as={as ?? Fragment}
            enter="ease-out duration-300"
            style={{
              width: isBlock ? '70rem' : '',
            }}
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            {children}
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default Modal;
