import axios from 'axios';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { assignees as assigneesConstant } from '../constants';

import {
  getLatestUpdates,
  fetchAssignees as fetchAssigneesAsync,
} from 'features/clients/Client/agencyClientSlice';

import { setAlert } from 'features/alerts/alertsSlice';
import { useUsers } from 'features/users';

export default function useClientAssignees() {
  const dispatch = useDispatch();
  const { statuses } = useUsers();

  const fetchAssignees = useCallback(
    async (clientId) => {
      await dispatch(await fetchAssigneesAsync(clientId));
    },
    [dispatch]
  );

  const updateAssignee = async (agencyClientId, userId, role) => {
    try {
      const response = await axios.post('/agency/clients/assignees', {
        agencyClientId,
        userId,
        role,
      });

      if (response.data.success) {
        dispatch(getLatestUpdates(agencyClientId));
        dispatch(
          setAlert('success', 'User assigned to role', 'Updated Successfully')
        );

        dispatch(await fetchAssigneesAsync(agencyClientId));
      }
    } catch (error) {
      dispatch(
        setAlert(
          'error',
          'Operation Failed',
          'User assignment was not successful'
        )
      );
    }
  };

  const deleteAssignee = async (agencyClientId, userId) => {
    await axios.delete('/agency/clients/assignees', {
      data: { agencyClientId, userId },
    });

    dispatch(await fetchAssigneesAsync(agencyClientId));
  };

  return {
    ...assigneesConstant,
    assigneesConstant,

    fetchAssignees,
    updateAssignee,
    deleteAssignee,
  };
}
