import { useTranslation } from 'react-i18next';
import {
  ChartBarIcon,
  UsersIcon,
  PresentationChartLineIcon,
  KeyIcon,
  TagIcon,
  RefreshIcon,
  CogIcon,
  AnnotationIcon,
} from '@heroicons/react/outline';
import usePermissions from 'hooks/usePermissions';

const useAdminMenu = () => {
  const { t } = useTranslation();
  const { userCan, isAgencyLevel, isAgencySuperUser } = usePermissions();

  return {
    name: 'Admin',
    href: '/employees',
    icon: '#admin',
    iconType: 'svg',
    visible: () => {
      return (
        isAgencySuperUser() ||
        userCan('employees.list') ||
        userCan('employees.orgChart.view') ||
        userCan('roles.list') ||
        userCan('churn.view') ||
        userCan('reports.view') ||
        userCan('settings.maintenance.update')
      );
    },
    children: [
      {
        name: t('Dashboard.Employees'),
        href: '/employees',
        icon: UsersIcon,
        visible: () =>
          userCan('employees.list') ||
          userCan('employees.orgChart.view') ||
          userCan('roles.list'),
      },
      {
        name: 'Employee Survey',
        href: '/employee-surveys',
        icon: AnnotationIcon,
        visible: () => userCan('employees.survey.list'),
      },
      {
        name: 'Partners',
        href: '/partners',
        icon: ChartBarIcon,
        visible: () => true,
      },
      {
        name: 'Churn',
        href: '/churn',
        icon: PresentationChartLineIcon,
        visible: () => {
          return isAgencyLevel() && userCan('churn.view');
        },
      },
      {
        name: 'Reports',
        href: '/reports',
        icon: ChartBarIcon,
        visible: () => isAgencyLevel() && userCan('reports.view'),
      },
      {
        name: 'Data Sync',
        href: '/data-sync',
        icon: RefreshIcon,
        visible: () => isAgencyLevel(),
      },
      {
        name: 'Settings',
        href: '/system-settings',
        icon: CogIcon,
        visible: () => userCan('settings.maintenance.update'),
      },
      {
        name: 'Employee Survey',
        href: '/employee-surveys',
        icon: AnnotationIcon,
        visible: () => userCan('employees.survey.list'),
      },
      {
        name: 'Task Management',
        href: '/task-management',
        icon: CogIcon,
        visible: () => true,
      },
      {
        name: 'Progress Reports',
        href: '/progress-report',
        icon: CogIcon,
        visible: () => true,
      },
    ],
  };
};

export default useAdminMenu;
