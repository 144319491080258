import React, { useEffect, useState } from 'react';
import axios from 'axios';
import ReactTooltip from 'react-tooltip';
import { Link } from 'react-router-dom';
import { dateFormatter, strUnderscoreToSpace } from 'utils/formatters';
import { columnClasses, headerClasses } from 'utils/table';
import Badge from 'components/Badge';
import TabNav from 'components/TabNav';
import { useDispatch, useSelector } from 'react-redux';
import usePermissions from 'hooks/usePermissions';
import { PencilIcon } from '@heroicons/react/solid';
import { SearchIcon, MailIcon, BanIcon } from '@heroicons/react/outline';
import DropdownMenu from 'components/DropdownMenu';
import { Menu } from '@headlessui/react';
import useQueryParams from 'hooks/useQueryParams';
import { ConfirmationModal, Table } from 'components';
import useAlert from 'hooks/useAlert';
import EmailInvoiceModal from './Invoice/components/EmailInvoiceModal';
import usePdf from 'hooks/usePdf';
import { fetchInvoices } from './invoicesSlice';
import PriceLabel from 'components/PriceLabel';
import ExportByDate from 'features/billing/components/ExportByDate';
import InvoiceSearch from './components/InvoiceSearch';

const RowActions = ({
  row,
  setInvoiceId,
  setIsOpenVoid,
  params,
  onOpenEmailInvoiceModal,
}) => {
  const { userCan } = usePermissions();
  const { onPreviewPdf } = usePdf();

  return (
    <span className="flex justify-start items-center space-x-3">
      {userCan('invoices.pdf.download') && (
        <button
          onClick={() => onPreviewPdf(`agency/invoices/${row.invoice_id}/pdf`)}
        >
          <ReactTooltip
            id="View Invoice"
            className="shadow-sm rounded-lg opacity-100"
          />
          <SearchIcon
            data-for="View Invoice"
            data-tip="View Invoice"
            className="h-6 w-6 text-custom-blue"
          />
        </button>
      )}
      {userCan('invoices.details.view') && (
        <Link
          to={`/invoices/${row.invoice_id}`}
          className="text-red-500 hover:text-red-700"
        >
          <ReactTooltip
            id="Edit Invoice"
            effect="solid"
            className="shadow-sm rounded-lg opacity-100"
          />
          <PencilIcon
            data-for="Edit Invoice"
            data-tip="Edit Invoice"
            className="h-6 w-6"
            style={{ color: 'rgb(21 94 117)' }}
          />
        </Link>
      )}
      {userCan('invoices.email') && params.status !== 'Paid' && (
        <DropdownMenu
          title={
            <>
              <ReactTooltip
                id="Mail Invoice"
                className="shadow-sm rounded-lg opacity-100"
              />
              <MailIcon
                data-for="Mail Invoice"
                data-tip="Mail Invoice"
                className="h-6 w-6 text-custom-blue"
              />
            </>
          }
          titleClasses="relative top-0.5"
          button
          buttonBg="bg-transparent"
          buttonFontWeight="font-normal"
          hoverClasses="bg-none"
          textColor="text-cyan-600"
          classes="text-sm  rounded-2xl"
          buttonRounded=""
          hoverText="hover:text-cyan-500"
          dropdownWidth="w-48"
          padding="0px"
          position="absolute"
          hideArrow
          zIndex=""
        >
          <div className="px-1 py-1 flex flex-col">
            <Menu.Item>
              <button
                onClick={() => {
                  onOpenEmailInvoiceModal(row, 'overdue');
                }}
                className="py-2 px-4 text-left hover:bg-gray-100 text-sm text-gray-600 hover:text-cyan-500"
              >
                Overdue follow up
              </button>
            </Menu.Item>
            <Menu.Item>
              <button
                onClick={() => {
                  onOpenEmailInvoiceModal(row, 'pastDue');
                }}
                className="py-2 px-4 text-left hover:bg-gray-100 text-sm text-gray-600 hover:text-cyan-500"
              >
                Past due date
              </button>
            </Menu.Item>

            <Menu.Item>
              <button
                onClick={() => {
                  onOpenEmailInvoiceModal(row, 'invoiceReminder');
                }}
                className="py-2 px-4 text-left hover:bg-gray-100 text-sm text-gray-600 hover:text-cyan-500"
              >
                Invoice reminder
              </button>
            </Menu.Item>
            <Menu.Item>
              <button
                onClick={() => {
                  onOpenEmailInvoiceModal(row, 'custom');
                }}
                className="py-2 px-4 text-left hover:bg-gray-100 text-sm text-gray-600 hover:text-cyan-500"
              >
                Custom email
              </button>
            </Menu.Item>
          </div>
        </DropdownMenu>
      )}
      {row.balance > 0 && row.status !== 'void' && userCan('invoices.void') && (
        <button
          onClick={() => {
            setInvoiceId(row.invoice_id);
            setIsOpenVoid(true);
          }}
        >
          <ReactTooltip
            id="Void Invoice"
            className="shadow-sm rounded-lg opacity-100"
          />

          <BanIcon
            data-for="Void Invoice"
            data-tip="Void Invoice"
            className="h-6 w-6 text-custom-blue"
          />
        </button>
      )}
    </span>
  );
};

const Overview = () => {
  const dispatch = useDispatch();
  const { userCan, isAgencySuperUser } = usePermissions();
  const { alertSuccess, alertError, alert } = useAlert();
  const { invoices, paginationParams } = useSelector((state) => state.invoices);
  const { params, updateParams } = useQueryParams(paginationParams);
  const [isOpenVoid, setIsOpenVoid] = useState(false);
  const [savingStatus, setSavingStatus] = useState(false);
  const [loading, setLoading] = useState(false);
  const [invoice, setInvoice] = useState(null);
  const [isOpenEmailModal, setIsOpenEmailModal] = useState(false);
  const [emailType, setEmailType] = useState(undefined);
  const [invoiceId, setInvoiceId] = useState();

  const [tabs, setTabs] = useState([
    {
      name: 'Draft',
      current: params.status == 'Draft',
      visible: userCan('invoices.draft.view'),
    },
    {
      name: 'Pending',
      current: params.status == 'Pending',
      className: '',
      visible: userCan('invoices.pending.view'),
    },
    {
      name: 'Sent',
      current: params.status == 'Sent',
      className: '',
      visible: userCan('invoices.sent.view'),
    },
    {
      name: 'OverDue',
      current: params.status == 'OverDue',
      className: '',
      visible: userCan('invoices.overdue.view'),
    },
    {
      name: 'Paid',
      current: params.status == 'Paid',
      className: '',
      visible: userCan('invoices.paid.view'),
    },
    {
      displayName: 'Partially Paid',
      name: 'PartiallyPaid',
      current: params.status == 'PartiallyPaid',
      className: '',
      visible: userCan('invoices.partially_paid.view'),
    },
    {
      name: 'Void',
      current: params.status == 'Void',
      className: '',
      visible: userCan('invoices.void.view'),
    },
    {
      name: 'Unpaid',
      current: params.status == 'Unpaid',
      className: '',
      visible: userCan('invoices.unpaid.view'),
    },
  ]);

  useEffect(() => {
    let myTabs = [...tabs];
    let currentTab = myTabs.find((t) => t.current === true);
    currentTab.current = false;
    let selectedTab = myTabs.find((t) => t.name == params.status);
    selectedTab.current = true;
    setTabs(myTabs);
  }, [params.status]);

  useEffect(() => {
    if (params.page > 0) {
      setLoading(true);
      dispatch(fetchInvoices(params)).then(() => {
        setLoading(false);
      });
    }
  }, [params, dispatch]);

  const updateStatus = (status) => {
    updateParams({
      status,
      page: 1,
      sizePerPage: params.sizePerPage,
    });
  };

  const voidInvoice = async () => {
    setSavingStatus(true);
    try {
      const res = await axios.post(`agency/invoices/${invoiceId}/void`);
      updateStatus(params.status);
      alertSuccess('success', res.data.data.message);
    } catch (error) {
      alertError('error', error.response.data.message);
    } finally {
      setIsOpenVoid(false);
      setSavingStatus(false);
    }
  };

  const onOpenEmailInvoiceModal = async (row, type) => {
    try {
      const response = await axios.get(`/agency/invoices/${row.invoice_id}`);

      const { invoice } = response.data.data;
      setInvoice(invoice);
      setEmailType(type);
      setIsOpenEmailModal(true);
    } catch (err) {
      alertError('Fetch invoice failed', err.response.data.message);
    }
  };

  const tableColumns = [
    {
      dataField: 'customer_name',
      text: 'Client Name',
      headerStyle: {
        minWidth: '250px',
        position: 'sticky',
        left: 0,
        zIndex: 1,
        backgroundColor: '#fff',
      },
      style: {
        whiteSpace: 'normal',
        backgroundColor: '#fff',
        position: 'sticky',
        left: 0,
        zIndex: 1,
      },
      formatter: (cell, row) => {
        return userCan('clients.profile.view') ? (
          <Link
            className="text-secondary-light hover:text-secondary"
            to={`/clients/account/${row.cf_bs_account_id_unformatted}`}
          >
            {cell}
          </Link>
        ) : (
          cell
        );
      },
    },
    {
      dataField: 'email',
      text: 'Client Email',
      headerClasses,
      headerStyle: {
        width: '250px',
      },
      style: {
        maxWidth: '250px',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
      },
      formatter: (cell, row) => {
        return (
          <>
            <span data-tip data-for={row.invoice_id}>
              {cell}
            </span>
            <ReactTooltip
              id={row.invoice_id}
              effect="solid"
              className="shadow-lg rounded-lg opacity-100"
            >
              <span>{cell}</span>
            </ReactTooltip>
          </>
        );
      },
    },
    {
      dataField: 'number',
      text: 'Invoice Number',
      headerClasses,
      headerStyle: {
        minWidth: '150px',
      },
      formatter: (cell, row) => {
        return userCan('invoices.details.view') ? (
          <Link
            to={`/invoices/${row.invoice_id}`}
            className="text-secondary-light hover:text-secondary"
          >
            {cell}
          </Link>
        ) : (
          cell
        );
      },
    },
    isAgencySuperUser() && {
      dataField: 'status',
      text: 'Revenue Type',
      headerClasses,
      headerStyle: {
        width: '150px',
      },
      formatter: (cell, row) => {
        return (
          <div className="flex flex-wrap">
            <Badge
              color={cell === 'paid' ? 'green' : 'red'}
              classes="uppercase mr-2 my-1"
              rounded="md"
            >
              {strUnderscoreToSpace(
                row.status === 'paid' ? 'Earned' : 'Unearned'
              )}
            </Badge>
          </div>
        );
      },
    },
    {
      dataField: 'invoice_date',
      text: 'Invoice Date',
      headerClasses,
      headerStyle: {
        minWidth: '150px',
      },
      formatter: (cell, row) => {
        return <span>{dateFormatter(cell)}</span>;
      },
    },

    {
      dataField: 'due_date',
      text: 'Due Date',
      headerClasses,
      headerStyle: {
        minWidth: '150px',
      },
      formatter: (cell, row) => {
        return <span>{dateFormatter(cell)}</span>;
      },
    },
    {
      dataField: 'total',
      text: 'Amount',
      headerClasses: `${headerClasses} text-right`,
      headerStyle: {
        minWidth: '150px',
      },
      formatter: (cell, row) => <PriceLabel price={cell} />,
    },
    {
      dataField: 'balance',
      text: 'Balance',
      headerClasses: `${headerClasses} text-right`,
      headerStyle: {
        minWidth: '150px',
      },
      formatter: (cell, row) => <PriceLabel price={cell} />,
    },
    {
      dataField: 'action',
      text: 'Action',
      sort: false,
      headerClasses: `${headerClasses} text-center`,
      headerStyle: {
        minWidth: '150px',
      },
      formatter: (cell, row) => {
        return (
          <RowActions
            row={row}
            setInvoiceId={setInvoiceId}
            setIsOpenVoid={setIsOpenVoid}
            params={params}
            onOpenEmailInvoiceModal={onOpenEmailInvoiceModal}
          />
        );
      },
    },
  ];

  const onTableChange = (type, { page, sizePerPage, sortField, sortOrder }) => {
    updateParams({ page, sizePerPage });
  };

  return (
    <div>
      <div className="flex mb-8 justify-between">
        <h1 className="text-3xl tracking-wide font-bold">Invoices</h1>

        <div className="flex items-center space-x-2">
          <InvoiceSearch />
          <ExportByDate
            params={paginationParams}
            url="/agency/invoices/export"
            fileName="invoices"
          />
        </div>
      </div>

      <TabNav
        tabs={tabs}
        setTabs={setTabs}
        onSelectChange={(e) => updateStatus(e.target.value)}
        onClick={(selectedTab) => updateStatus(selectedTab.name)}
        theme="tabbed"
      />
      <Table
        columns={tableColumns.map((column) => ({
          ...column,
          classes:
            column.classes ??
            'text-13 leading-1.5 text-grayscale-800 py-2.5 px-3',
        }))}
        data={invoices}
        onTableChange={onTableChange}
        params={params}
        keyField="invoice_id"
        loading={loading}
        paginationType="prevnext"
      />
      <EmailInvoiceModal
        invoice={invoice}
        open={isOpenEmailModal}
        setOpen={setIsOpenEmailModal}
        type={emailType}
      />
      <ConfirmationModal
        title="Void Invoice"
        content="Are you sure you want to void this invoice?"
        open={isOpenVoid}
        setOpen={setIsOpenVoid}
        onOkClick={voidInvoice}
        onCancelClick={() => setIsOpenVoid(false)}
        okLoading={savingStatus}
        showOkLoading={true}
      />
    </div>
  );
};
export default Overview;
