import axios from 'axios';
import ReactTooltip from 'react-tooltip';

import { debounce, omit } from 'lodash';
import { useState, useEffect } from 'react';
import { ExclamationCircleIcon } from '@heroicons/react/outline';
import { XIcon, BadgeCheckIcon, SearchIcon } from '@heroicons/react/solid';

import useQueryParams from 'hooks/useQueryParams';

import { InputPrepend } from 'components/Forms';
import ColumnPicker from 'components/Table/ColumnPicker';
import { Table, UserDisplay, UserSelector } from 'components';

import ClientLink from './ClientLink';
import DateEditor from './DateEditor';
import StatusBadge from './StatusBadge';
import ClientFilters from './ClientFilters';
import SubscriptionStatus from './SubscriptionStatus';
import Export from './Export';

import useClientAssignees from 'features/clients/Client/useClientAssignees';
import ComplaintStatus from 'features/complaint/components/ComplaintStatus';

import {
  currencyFormatter,
  dateFormatter,
  dateFormatterUTC,
} from 'utils/formatters';
import InvoiceSummary from './InvoiceSummary';

const ClientList = ({
  title,
  reload = false,
  customizableColumns = true,
  initialFilters = [],
  defaultColumns = [],
  editableColumns = [],
  excludeColumns = [],
  allowedFilters = [
    'service',
    'accountStatus',
    'subscriptionStatus',
    'latestComplaintStatus',
    'brandStrategist',
    'contractSigned',
    'accountSpecialist',
    'subscriptionPaymentOption',
  ],
  ActionComponent,
  children,
  initialParams = {
    page: 1,
    pageSize: 10,
    sorts: 'client:asc',
    search: '',
    filters: '[]',
    include: [
      'subscription',
      'defaultContact',
      'projectManager[user[avatar]]',
      'brandStrategist[user[avatar]]',
      'accountSpecialist[user[avatar]]',
    ],
    attributes: [
      'phone',
      'client',
      'status',
      'service',
      'address',
      'website',
      'terminatedAt',
      'isOnboarding',
      'accountStatus',
      'amazonPageUrl',
      'agencyClientId',
      'draftMarketplace',
      'latestComplaintStatus',
      'isSPAPIConnected',
      'isADVAPIConnected',
      'contractSigned',
      'terminationReason',
      'invoiceSummary',
      'salesClientId',
      'totalPaidInvoiceAmount',
      'totalUnPaidInvoiceAmount',
    ],
  },
  setFilterGroups = false,
}) => {
  const [loading, setLoading] = useState(true);
  const [clients, setClients] = useState({ rows: [] });

  const { params, updateParams } = useQueryParams(
    omit(initialParams, ['attributes', 'include'])
  );

  const { assigneesConstant, updateAssignee, deleteAssignee } =
    useClientAssignees();

  useEffect(() => {
    let isSubscribed = true;

    const fetchData = async () => {
      setLoading(true);

      const response = await axios.get('/v2/agency/clients/list', {
        params: {
          ...omit(params, ['filters']),
          include: initialParams.include,
          attributes: initialParams.attributes,
          filterGroups: JSON.stringify([
            {
              filters: [...initialFilters, ...JSON.parse(params.filters)],
            },
          ]),
        },
      });

      if (isSubscribed) {
        setClients(response.data.data);
        setLoading(false);
      }
    };

    fetchData().catch(console.error);

    return () => (isSubscribed = false);
  }, [
    params,
    reload,
    initialParams.include,
    initialParams.attributes,
    initialFilters,
  ]);

  const refreshList = async () => {
    const response = await axios.get('/v2/agency/clients/list', {
      params: {
        ...omit(params, ['filters']),
        include: initialParams.include,
        attributes: initialParams.attributes,
        filterGroups: JSON.stringify([
          { filters: [...initialFilters, ...JSON.parse(params.filters)] },
        ]),
      },
    });

    setClients(response.data.data);
  };

  const Assignee = ({ agencyClientId, value, params, role, disabled }) => (
    <UserSelector
      size="40px"
      iconSize="16px"
      params={params}
      value={value}
      disabled={disabled}
      onChange={({ userId }) =>
        updateAssignee(agencyClientId, userId, role).then(() => refreshList())
      }
      onDelete={({ userId }) =>
        deleteAssignee(agencyClientId, userId).then(() => refreshList())
      }
    />
  );

  const columns = [
    {
      dataField: 'client',
      text: 'Client Name',
      sort: true,
      show: true,
      hideable: false,
      headerStyle: {
        minWidth: '250px',
        position: 'sticky',
        left: 0,
        zIndex: 1,
        backgroundColor: '#fff',
      },
      style: {
        whiteSpace: 'normal',
        backgroundColor: '#fff',
        position: 'sticky',
        left: 0,
        zIndex: 1,
      },
      formatter: (cell, row) => (
        <ClientLink client={row}>
          <span className="text-secondary-light">{cell}</span>
        </ClientLink>
      ),
    },
    {
      dataField: 'service',
      text: 'Service',
      sort: true,
      hideable: true,
      headerStyle: { minWidth: '150px' },
      show: defaultColumns.includes('service'),
    },
    {
      dataField: 'address',
      text: 'Address',
      sort: true,
      hideable: true,
      headerStyle: { minWidth: '50px' },
      show: defaultColumns.includes('address'),
    },
    {
      dataField: 'website',
      text: 'Website',
      sort: true,
      hideable: true,
      headerStyle: { minWidth: '50px' },
      show: defaultColumns.includes('website'),
    },
    {
      dataField: 'amazonPageUrl',
      text: 'Amazon Page Url',
      sort: true,
      hideable: true,
      headerStyle: { minWidth: '150px' },
      show: defaultColumns.includes('amazonPageUrl'),
    },
    {
      dataField: 'phone',
      text: 'Phone',
      sort: true,
      hideable: true,
      headerStyle: { minWidth: '50px' },
      show: defaultColumns.includes('phone'),
    },
    {
      dataField: 'draftMarketplace',
      text: 'Default Marketplace',
      sort: true,
      hideable: true,
      headerStyle: { minWidth: '50px' },
      show: defaultColumns.includes('draftMarketplace'),
    },
    {
      dataField: 'accountStatus',
      text: 'Account Status',
      sort: true,
      hideable: true,
      headerStyle: { minWidth: '50px' },
      show: defaultColumns.includes('accountStatus'),
    },
    {
      dataField: 'status',
      text: 'Status',
      sort: true,
      hideable: true,
      formatter: (cell) => <StatusBadge status={cell} />,
      headerStyle: { minWidth: '50px' },
      show: defaultColumns.includes('status'),
    },
    {
      dataField: 'subscription',
      text: 'Subscription',
      sort: true,
      hideable: true,
      headerStyle: { minWidth: '180px' },
      formatter: (cell, row) => (
        <SubscriptionStatus subscription={row.subscription} />
      ),
      show: defaultColumns.includes('subscription'),
    },
    {
      dataField: 'subscriptionPaymentOption',
      text: 'Subscription Type',
      sort: true,
      hideable: true,
      headerStyle: { minWidth: '180px' },
      show: defaultColumns.includes('subscriptionPaymentOption'),
    },
    {
      dataField: 'subscription.subTotal',
      text: 'Subscription Sub Total',
      sort: true,
      hideable: true,
      headerStyle: { minWidth: '180px' },
      show: defaultColumns.includes('subscription.subTotal'),
      formatter: (cell) => currencyFormatter(cell),
    },
    {
      dataField: 'subscription.amount',
      text: 'Subscription Amount',
      sort: true,
      hideable: true,
      headerStyle: { minWidth: '180px' },
      show: defaultColumns.includes('subscription.amount'),
      formatter: (cell) => currencyFormatter(cell),
    },
    {
      dataField: 'invoiceSummary',
      text: 'Invoice Status',
      sort: true,
      hideable: true,
      headerStyle: { minWidth: '180px' },
      formatter: (cell) => <InvoiceSummary value={cell} />,
      show: defaultColumns.includes('invoiceSummary'),
    },
    {
      dataField: 'totalPaidInvoiceAmount',
      text: 'Amount',
      sort: true,
      hideable: true,
      headerStyle: { minWidth: '180px' },
      formatter: (cell) => currencyFormatter(cell),
      show: defaultColumns.includes('totalPaidInvoiceAmount'),
    },
    {
      dataField: 'totalUnPaidInvoiceAmount',
      text: 'Amount',
      sort: true,
      hideable: true,
      headerStyle: { minWidth: '180px' },
      formatter: (cell) => currencyFormatter(cell),
      show: defaultColumns.includes('totalUnPaidInvoiceAmount'),
    },
    {
      dataField: 'hasInvalidAssignee',
      text: 'Assignees',
      sort: false,
      hideable: true,
      headerStyle: { minWidth: '100px' },
      formatter: (cell, row) => {
        return cell ? (
          <div className="flex items-center justify-center">
            <div data-tip data-for={`assignee-icon-${row.agencyClientId}`}>
              <ExclamationCircleIcon className="w-5 h-5 text-orange-500 inline" />
            </div>

            <ReactTooltip
              id={`assignee-icon-${row.agencyClientId}`}
              place="bottom"
              effect="solid"
              backgroundColor="rgba(229, 231, 235, var(--tw-bg-opacity))"
              textColor="rgba(17, 24, 39, var(--tw-text-opacity))"
              padding="0 10px"
            >
              Some of the client assignees is invalid.
            </ReactTooltip>
          </div>
        ) : (
          ''
        );
      },
      show: defaultColumns.includes('hasInvalidAssignee'),
    },
    {
      dataField: 'isSPAPIConnected',
      text: 'SP-API',
      sort: false,
      hideable: true,
      headerStyle: { minWidth: '100px' },
      formatter: (cell) =>
        cell ? (
          <BadgeCheckIcon className="text-success-dark w-5 h-5 inline" />
        ) : (
          <XIcon className="text-error-dark w-5 h-5 inline" />
        ),
      show: defaultColumns.includes('isSPAPIConnected'),
    },
    {
      dataField: 'isADVAPIConnected',
      text: 'ADV-API',
      sort: false,
      hideable: true,
      headerStyle: { minWidth: '100px' },
      formatter: (cell) =>
        cell ? (
          <BadgeCheckIcon className="text-success-dark w-5 h-5 inline" />
        ) : (
          <XIcon className="text-error-dark w-5 h-5 inline" />
        ),
      show: defaultColumns.includes('isADVAPIConnected'),
    },
    {
      dataField: 'latestComplaintStatus',
      text: 'Complaint',
      sort: true,
      hideable: true,
      headerStyle: {
        minWidth: '180px',
      },
      formatter: (cell) => <ComplaintStatus status={cell} />,
      show: defaultColumns.includes('latestComplaintStatus'),
    },
    {
      dataField: 'subscription.activatedAt',
      text: 'Date Activated',
      sort: true,
      hideable: true,
      headerStyle: { minWidth: '150px' },
      show: defaultColumns.includes('subscription.activatedAt'),
      formatter: (cell) =>
        cell ? (
          <span className="font-normal">{dateFormatterUTC(cell)}</span>
        ) : (
          <>&mdash;</>
        ),
    },
    {
      dataField: 'createdAt',
      text: 'Date Created',
      sort: true,
      hideable: true,
      headerStyle: { minWidth: '150px' },
      show: defaultColumns.includes('createdAt'),
      formatter: (cell) => {
        return <span className="font-normal">{dateFormatter(cell)}</span>;
      },
    },
    // {
    //   dataField: 'projectManager.user.firstName',
    //   text: 'Project Manager',
    //   sort: false,
    //   hideable: true,
    //   headerStyle: {
    //     minWidth: '180px',
    //   },
    //   show: defaultColumns.includes('projectManager'),
    //   formatter: (cell, row) => {
    //     if (editableColumns.includes('projectManager')) {
    //       const roleCode =
    //         assigneesConstant.PROJECT_MANAGER_ASSIGNEE.role.value;

    //       return row.status === 'ACTIVE' ? (
    //         <Assignee
    //           role={roleCode}
    //           value={row.projectManager?.user}
    //           disabled={!!row.brandStrategist}
    //           agencyClientId={row.agencyClientId}
    //           params={{ 'role.code': roleCode, status: 'ACTIVE' }}
    //         />
    //       ) : null;
    //     }

    //     return (
    //       cell && (
    //         <UserDisplay bgColor="#1879D8" user={row.projectManager?.user} />
    //       )
    //     );
    //   },
    // },
    {
      dataField: 'brandStrategist.user.firstName',
      text: 'Brand Strategist',
      sort: false,
      hideable: true,
      headerStyle: {
        minWidth: '180px',
      },
      show: defaultColumns.includes('brandStrategist'),
      formatter: (cell, row) => {
        if (editableColumns.includes('brandStrategist')) {
          const roleCode =
            assigneesConstant.BRAND_STRATEGIST_ASSIGNEE.role.value;

          let params = { 'role.code': roleCode, status: 'ACTIVE' };

          if (row.projectManager) {
            params.supervisorId = row.projectManager.userId;
          }

          return row.status === 'ACTIVE' ? (
            <Assignee
              params={params}
              role={roleCode}
              value={row.brandStrategist?.user}
              disabled={!!row.accountSpecialist}
              agencyClientId={row.agencyClientId}
            />
          ) : null;
        }

        return (
          cell && (
            <UserDisplay bgColor="#1879D8" user={row.brandStrategist?.user} />
          )
        );
      },
    },
    {
      dataField: 'accountSpecialist.user.firstName',
      text: 'Account Specialist',
      sort: false,
      hideable: true,
      headerStyle: {
        minWidth: '180px',
      },
      show: defaultColumns.includes('accountSpecialist'),
      formatter: (cell, row) => {
        if (editableColumns.includes('accountSpecialist')) {
          const roleCode =
            assigneesConstant.ACCOUNT_SPECIALIST_ASSIGNEE.role.value;

          let params = { 'role.code': roleCode, status: 'ACTIVE' };

          if (row.brandStrategist) {
            params.supervisorId = row.brandStrategist.userId;
          }

          return row.status === 'ACTIVE' ? (
            <Assignee
              params={params}
              role={roleCode}
              value={row.accountSpecialist?.user}
              disabled={!!!row.brandStrategist || !!!row.projectManager}
              agencyClientId={row.agencyClientId}
            />
          ) : null;
        }

        return (
          cell && (
            <UserDisplay bgColor="#1879D8" user={row.accountSpecialist?.user} />
          )
        );
      },
    },
    {
      dataField: 'contractSigned',
      text: 'Contract Signed',
      sort: true,
      hideable: true,
      headerStyle: { minWidth: '150px' },
      show: defaultColumns.includes('contractSigned'),
      formatter: (cell, row) =>
        editableColumns.includes('contractSigned') ? (
          <DateEditor
            attribute="contractSigned"
            onChange={() => refreshList()}
            clientId={row.agencyClientId}
            value={cell}
          />
        ) : cell ? (
          dateFormatterUTC(cell)
        ) : (
          <>&mdash;</>
        ),
    },
    {
      dataField: 'terminatedAt',
      text: 'Terminated At',
      sort: true,
      hideable: true,
      headerStyle: { minWidth: '150px' },
      show: defaultColumns.includes('terminatedAt'),
      formatter: (cell, row) =>
        editableColumns.includes('terminatedAt') ? (
          <DateEditor
            attribute="terminatedAt"
            onChange={() => refreshList()}
            clientId={row.agencyClientId}
            value={cell}
          />
        ) : cell ? (
          dateFormatterUTC(cell)
        ) : (
          <>&mdash;</>
        ),
    },
    {
      dataField: 'terminationReason',
      text: 'Termination Reason',
      sort: true,
      hideable: true,
      headerStyle: { minWidth: '150px' },
      show: defaultColumns.includes('terminationReason'),
    },
    {
      dataField: 'termination.createdAt',
      text: 'Submission Date',
      sort: true,
      hideable: true,
      headerStyle: { minWidth: '150px' },
      show: defaultColumns.includes('termination.createdAt'),
      formatter: (cell) =>
        cell ? (
          <span className="font-normal">{dateFormatterUTC(cell)}</span>
        ) : (
          <>&mdash;</>
        ),
    },
    {
      dataField: 'termination.requestor.firstName',
      text: 'Submitted By',
      sort: true,
      hideable: true,
      headerStyle: { minWidth: '150px' },
      show: defaultColumns.includes('termination.requestor'),
      formatter: (cell, row) =>
        cell ? (
          <UserDisplay bgColor="#1879D8" user={row.termination.requestor} />
        ) : (
          <>&mdash;</>
        ),
    },
    {
      dataField: 'termination.evaluator.firstName',
      text: 'Approver',
      sort: true,
      hideable: true,
      headerStyle: { minWidth: '150px' },
      show: defaultColumns.includes('termination.evaluator'),
      formatter: (cell, row) =>
        cell ? (
          <UserDisplay bgColor="#1879D8" user={row.termination.evaluator} />
        ) : (
          <>&mdash;</>
        ),
    },
    {
      dataField: 'defaultContact.firstName',
      text: 'Contact',
      sort: true,
      hideable: true,
      headerStyle: { minWidth: '200px' },
      show: defaultColumns.includes('defaultContact.firstName'),
      formatter: (cell, row) =>
        cell ? (
          <UserDisplay bgColor="#1879D8" user={row.defaultContact} />
        ) : (
          <>&mdash;</>
        ),
    },
    {
      dataField: 'defaultContact.email',
      text: 'Email',
      sort: true,
      hideable: true,
      headerStyle: { minWidth: '80px' },
      formatter: (cell) => cell ?? <>&mdash;</>,
      show: defaultColumns.includes('defaultContact.email'),
    },
    {
      dataField: 'onboardingTaskCompletionPercentage',
      text: 'Progress',
      sort: true,
      hideable: false,
      headerStyle: {
        minWidth: '265px',
      },
      formatter: (cell, row) => {
        const percentage = Math.round(cell * 100);
        let color = percentage === 100 ? '#00BA88' : '#F9DC7D';
        let width = `${percentage * 2}px`;

        return (
          <div className="flex justify-start items-center gap-2">
            <div className="font-normal font-sourceSansPro text-13 leading-1.5">
              {percentage}%
            </div>
            <div
              style={{ backgroundColor: `${color}`, width: `${width}` }}
              className={`h-4`}
            ></div>
          </div>
        );
      },
      show: defaultColumns.includes('onboardingTaskCompletionPercentage'),
    },
    {
      dataField: 'agencyClientId',
      text: 'Action',
      sort: true,
      hideable: false,
      show: !!ActionComponent,
      headerStyle: { minWidth: '80px' },
      formatter: (cell, row) =>
        ActionComponent ? <ActionComponent clientId={cell} row={row} /> : null,
    },
  ];

  const [tableColumns, setTableColumns] = useState(
    columns.filter((column) => !excludeColumns.includes(column.dataField))
  );

  useEffect(() => {
    setTableColumns(
      columns.filter((column) => !excludeColumns.includes(column.dataField))
    );
  }, []);

  const onTableChange = (type, { page, sizePerPage, sortField, sortOrder }) => {
    let newParams = { ...params, page, pageSize: sizePerPage };
    delete newParams.sorts;

    if (sortField && sortOrder) {
      newParams.sorts = `${sortField}:${sortOrder}`;
    }

    updateParams(newParams);
  };

  const onDebouncedSearch = debounce((e) => {
    updateParams({ ...params, search: e.target.value });
  }, 500);

  return (
    <div id="client-lists">
      <div className="flex items-center mb-5 justify-between">
        <h2 className="font-inter text-25 leading-1.2 tracking-3/4 text-grayscale-900 font-bold">
          {title}
        </h2>

        <div className="flex items-center space-x-4">
          <InputPrepend
            type="text"
            name="search"
            defaultValue={params.search}
            onChange={(e) => onDebouncedSearch(e)}
            placeholder={'Search'}
            prependText={<SearchIcon className="w-4 h-4" />}
            border="border-white"
          />

          {customizableColumns && (
            <ColumnPicker
              showTitle={true}
              options={tableColumns}
              setTableColumns={setTableColumns}
            />
          )}

          <ClientFilters
            allowed={allowedFilters}
            filters={JSON.parse(params.filters)}
            setFilters={(filters) => {
              updateParams({ ...params, filters: JSON.stringify(filters) });
              setFilterGroups && setFilterGroups([{ filters: [...filters] }]);
            }}
          />
          {title === 'Active Clients' && <Export />}
        </div>
      </div>

      {children}

      <Table
        columns={tableColumns
          .filter((column) => column.show)
          .map((column) => {
            return {
              ...column,
              classes:
                column.classes ??
                'text-13 leading-1.5 text-grayscale-800 py-2.5 px-3',
            };
          })}
        data={clients}
        onTableChange={onTableChange}
        params={params}
        keyField="agencyClientId"
        loading={loading}
      />
    </div>
  );
};

export default ClientList;
