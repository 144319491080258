import axios from 'axios';
import moment from 'moment';
import { debounce } from 'lodash';
import { DndProvider } from 'react-dnd';
import { useEffect, useState } from 'react';
import { SearchIcon } from '@heroicons/react/outline';
import { useDispatch, useSelector } from 'react-redux';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { setBoardView } from 'features/tasks/tasksSlice';
import ButtonLink from 'components/ButtonLink';
import InputPrepend from 'components/Forms/InputPrepend';
import TaskDetailsPage from 'features/tasks/TaskDetailsPage';
import TaskGroup from 'features/tasks/components/workspace/TaskGroup';
import useBodyClass from 'hooks/useBodyClass';
import useQueryParams from 'hooks/useQueryParams';
import useTaskCategories from 'hooks/useTaskCategories';
import classNames from 'utils/classNames';
import FiltersDropdown from './components/FiltersDropdown';
import { setAgencyUsers } from './salesSlice';
import { parseArray } from 'utils/formatters';
import useLoggedInUser from 'hooks/useLoggedInUser';

const groups = [
  { label: 'Status', value: 'status' },
  { label: 'Priority', value: 'priority' },
  { label: 'Due Date', value: 'due' },
];

const statuses = [
  {
    color: '#002F5D',
    order: 1,
    type: 'open',
    name: 'Pending',
    bgColor: '#D3E4F5',
    params: { filter: { status: 'Pending' } },
  },
  {
    order: 2,
    type: 'open',
    name: 'In Progress',
    color: '#BFA654',
    bgColor: '#FFEFD0',
    params: { filter: { status: 'In Progress' } },
  },
  {
    order: 3,
    type: 'closed',
    name: 'Done',
    color: '#00966D',
    bgColor: '#CFFFDD',
    params: { filter: { status: 'Done' } },
  },
];

const Workspace = () => {
  const dispatch = useDispatch();
  const { url } = useRouteMatch();
  const { priorities, due } = useTaskCategories();
  const { boardView } = useSelector((state) => state.tasks);
  useBodyClass(['tasks-light', 'tasks', boardView]);

  const [users, setUsers] = useState([]);
  const [reload, setReload] = useState(false);
  const [taskListOptions, setTaskListOptions] = useState([]);
  const { user } = useLoggedInUser();

  const { params, updateParams } = useQueryParams({
    search: '',
    groupBy: 'status',
  });

  const onSearch = (e) => {
    updateParams({ ...params, search: e.target.value });
  };

  const onDebouncedSearch = debounce((e) => {
    onSearch(e);
  }, 500);

  const updateGroupBy = (value) => {
    updateParams({ ...params, groupBy: value });
  };

  const onSetFilters = (values) => {
    updateParams({ ...params, ...values });
  };

  useEffect(() => {
    let isSubscribed = true;

    const fetchData = async () => {
      const response = await axios.get('/users', {
        params: {
          roleLevel: 'agency',
          pageSize: 1000,
          attributes: ['userId', 'firstName', 'lastName', 'isMine'],
          sort: 'isMine',
          permissionAccess: `sales.profiles.list`,
        },
      });

      if (isSubscribed) {
        setUsers(response.data.data.rows);
        dispatch(setAgencyUsers(response.data.data.rows));
      }
    };

    fetchData().catch(console.error);

    return () => (isSubscribed = false);
  }, [dispatch]);

  useEffect(() => {
    let isSubscribed = true;

    const fetchData = async () => {
      const response = await axios.get('/agency/tasks/lists', {
        params: {
          pageSize: 10000,
          scopes: ['underSalesClient'],
          include: ['folder.space.salesClient'],
          codes: ['client_operation_task_list'],
          sort: 'folder.space.salesClient.lead.companyName:asc',
          attributes: ['taskListId', 'taskFolderId', 'code', 'name'],
        },
      });

      if (isSubscribed) {
        setTaskListOptions(
          response.data.data.rows.map((i) => {
            return {
              taskListId: i.taskListId,
              taskFolderId: i.taskFolderId,
              code: i.code,
              name: i.name,
              taskSpaceId: i.folder.taskSpaceId,
              agencyClientId: i.folder.space.salesClient.salesClientId,
              client: i.folder.space.salesClient.lead.companyName,
            };
          })
        );
      }
    };

    fetchData().catch(console.error);

    return () => (isSubscribed = false);
  }, [dispatch, params]);

  useEffect(() => {
    //check if user has own sales columns setting start
    if (user.settings && user.settings.salesWorkspaceFilter) {
      onSetFilters(user.settings.salesWorkspaceFilter);
    }
    //check if user has own sales columns setting end
  }, [user]);

  return (
    <div className="">
      <div className="sm:grid grid-cols-8 pt-6 pb-4">
        <div className="col-span-4 flex items-center space-x-20">
          <h1 className="text-25 tracking-3/4 font-inter leading-1.2 font-bold text-grayscale-900">
            My Workspace
          </h1>
          <div className="border-b space-x-12 pb-1.5 relative -mb-2">
            <ButtonLink
              classes={classNames(
                boardView === 'list' ? 'font-semibold add-select-line' : '',
                'text-lg tracking-wide text-red-700 hover:text-red-400 font-inter relative'
              )}
              onClick={() => dispatch(setBoardView('list'))}
            >
              List
            </ButtonLink>
            <ButtonLink
              classes={classNames(
                boardView === 'kanban' ? 'font-semibold  add-select-line' : '',
                'text-lg tracking-wide text-red-700 hover:text-red-400 font-inter relative'
              )}
              onClick={() => dispatch(setBoardView('kanban'))}
            >
              Board
            </ButtonLink>
          </div>
        </div>
        <div className="col-span-1">&nbsp;</div>
        <div className="col-span-3 flex items-center justify-end space-x-4">
          <div className="w-2/5">
            <InputPrepend
              name="search"
              defaultValue={params?.search}
              onChange={(e) => onDebouncedSearch(e)}
              type="text"
              placeholder={'Search Tasks'}
              prependText={<SearchIcon className="w-4 h-4" />}
              border="border-white"
            />
          </div>
          <div className="w-2/5 flex items-center space-x-2">
            <label className="text-sm text-gray-500 w-auto whitespace-nowrap">
              Group By
            </label>
            <select
              label="status"
              value={params.groupBy}
              className="w-3/4 rounded-xl border-0 focus:ring-0 appearance-none focus:appearance-none focus:border-0 sm:text-sm disabled:bg-gray-100"
              onChange={(e) => updateGroupBy(e.target.value)}
            >
              {groups.map((group, i) => (
                <option key={i} value={group.value}>
                  {group.label}
                </option>
              ))}
            </select>
          </div>
          <div className="w-auto">
            <FiltersDropdown params={params} onSetFilters={onSetFilters} />
          </div>
        </div>
      </div>

      <div>
        <DndProvider backend={HTML5Backend}>
          <div className="space-y-3 list-container">
            {{
              due,
              status: statuses,
              priority: priorities,
            }[params.groupBy]
              ?.filter((category) => {
                return parseArray(params.statuses).length &&
                  params.groupBy === 'status'
                  ? parseArray(params.statuses).includes(category.name)
                  : true;
              })
              .map((category) => {
                let payload = {
                  pageSize: 5,
                  sort: params.sort ?? 'isAssignedToMe,updatedAt',
                  search: params.search,
                  statuses: parseArray(params.statuses),
                  types: parseArray(params.types),
                  assigneesUserIds: parseArray(params.assigneesUserIds),
                  ...(params.dueDate &&
                  Array.isArray(params.dueDate) &&
                  params.dueDate.length
                    ? {
                        dueDateBefore: moment(parseArray(params.dueDate)[1])
                          .tz(moment.tz.guess())
                          .endOf('d')
                          .format(),
                      }
                    : {}),
                  ...(params.dueDate &&
                  Array.isArray(params.dueDate) &&
                  params.dueDate.length
                    ? {
                        dueDateAfter: moment(parseArray(params.dueDate)[0])
                          .tz(moment.tz.guess())
                          .startOf('d')
                          .format(),
                      }
                    : {}),
                  ...(category.params.filter ?? {}),
                  taskListCodes: ['client_operation_task_list'],
                  scopes: [
                    'underSalesClient',
                    ...(category.params.scopes ?? []),
                  ],
                  include: [
                    'list.folder.space.salesClient',
                    'subtasks',
                    'assignees',
                  ],
                };

                if (params.groupBy === 'status') {
                  delete payload.statuses;
                }

                return (
                  <TaskGroup
                    reload={reload}
                    groupKey={params.groupBy}
                    withTaskType={true}
                    setReload={setReload}
                    boardView={boardView}
                    key={category.name}
                    canDragInBoardView={true}
                    canDragInListView={true}
                    title={category.name}
                    titleColor={category.color}
                    titleBgColor={category.bgColor}
                    assigneeOptions={users}
                    allowAddTask={true}
                    defaultStatus={statuses[0].name}
                    statusOptions={statuses}
                    taskListOptions={taskListOptions}
                    params={payload}
                    updateParams={updateParams}
                    taskRowColumns={[
                      { key: 'assignees', className: 'col-span-2' },
                      { key: 'dueDate', className: 'col-span-2' },
                      { key: 'priority', className: 'col-span-1' },
                      { key: 'type', className: 'col-span-1' },
                    ]}
                  />
                );
              })}
          </div>
        </DndProvider>
      </div>

      <Switch>
        <Route
          path={`${url}/:taskItemId`}
          render={() => {
            return (
              <TaskDetailsPage
                onChange={() => {
                  updateParams({
                    search: '',
                    groupBy: 'status',
                  });
                  setReload(!reload);
                }}
                assigneeOptions={users}
                notifyAssignee={true}
              />
            );
          }}
        />
      </Switch>
    </div>
  );
};

export default Workspace;
