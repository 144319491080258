import axios from 'axios';
import Loading from 'components/Loading';
import { useEffect, useLayoutEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory, useParams } from 'react-router-dom';
import useBodyClass from 'hooks/useBodyClass';
import usePermissions from 'hooks/usePermissions';
import { fetchInvoice, fetchInvoiceErrors } from './invoiceSlice';
import Actions from './Actions';
import { dateFormatter, strUnderscoreToSpace } from 'utils/formatters';
import useInvoice from 'hooks/useInvoice';
import classnames from 'classnames';
import Body from './components/Body';
import SuperLogs from './components/SuperLogs';
import PaymentsMade from './components/PaymentsMade';
import CreditsApplied from './components/CreditsApplied';
import AutoOptionEmail from './components/AutoOptionEmail';
import AutoOptionCollect from './components/AutoOptionCollect';
import CommissionErrors from './components/CommissionErrors';
import InvoiceUpdates from './InvoiceUpdates';
import TabNav from 'components/TabNav';
import useQueryParams from 'hooks/useQueryParams';
import { fetchAddons } from 'features/zoho/zohoSlice';
import RecentActivities from './components/RecentActivities';

const Invoice = () => {
  useBodyClass(['client-profile', 'invoice-page']);
  const { userCan, isAgencySuperUser } = usePermissions();
  const { invoiceId } = useParams();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const { invoice } = useSelector((state) => state.invoice);
  const { paginationParams } = useSelector((state) => state.invoices);
  const { addons } = useSelector((state) => state.zoho);
  const [tabs, setTabs] = useState(null);
  const history = useHistory();
  const status = useInvoice(invoice);
  const { updateQueryString } = useQueryParams(paginationParams);

  useLayoutEffect(() => {
    setTabs(null);
    const syncRecentActivities = async () => {
      await axios.get(`/agency/invoices/${invoiceId}/recentactivities`);
    };

    setLoading(true);
    dispatch(fetchInvoice({ invoiceId })).then((res) => {
      syncRecentActivities();
      updateTab(res.payload.status);
    });
    dispatch(fetchInvoiceErrors(invoiceId));

    setLoading(false);
  }, [dispatch, invoiceId]);

  useEffect(() => {
    if (!addons) {
      dispatch(fetchAddons());
    }
  }, [addons, dispatch]);

  const updateTab = (status) => {
    let myTabs = [
      {
        name: 'Draft',
        current: status == 'draft',
        visible: userCan('invoices.draft.view'),
      },
      {
        name: 'Pending',
        current: status == 'pending' || status == 'all',
        visible: userCan('invoices.pending.view'),
      },
      {
        name: 'Sent',
        current: status == 'sent',
        visible: userCan('invoices.sent.view'),
      },
      {
        name: 'OverDue',
        current: status == 'overdue',
        visible: userCan('invoices.overdue.view'),
      },
      {
        name: 'Paid',
        current: status == 'paid',
        visible: userCan('invoices.paid.view'),
      },
      {
        name: 'PartiallyPaid',
        current: status == 'partially_paid',
      },
      {
        name: 'Void',
        current: status == 'void',
        visible: userCan('invoices.void.view'),
      },
      {
        name: 'Unpaid',
        current: status == 'unpaid',
        visible: userCan('invoices.unpaid.view'),
      },
    ];
    setTabs(myTabs.filter((tab) => tab.visible !== false));
  };

  const onSelectChange = (e) => {
    history.push(`/invoices?${updateQueryString({ status: e.target.value })}`);
  };

  const onClick = (selectedTab) => {
    history.push(
      `/invoices?${updateQueryString({ status: selectedTab.name })}`
    );
  };

  return (
    <div>
      <div className="flex mb-8 justify-between">
        <h1 className="text-3xl tracking-wide font-bold">Invoices</h1>
      </div>

      {tabs && tabs.length > 0 && (
        <TabNav
          tabs={tabs}
          setTabs={setTabs}
          onSelectChange={onSelectChange}
          onClick={onClick}
          theme="tabbed"
        />
      )}

      {!loading && invoice ? (
        <div className="lg:grid grid-cols-5">
          <div className="client-content container-fixed bg-white">
            <div className="px-8 pt-8 pb-16 space-y-4">
              <h5 className="font-bold text-xl border-b pb-4 flex justify-between">
                {invoice.invoice_number}
                <div className="flex space-x-4 items-center">
                  <div
                    className={classnames(
                      'text-white text-xs px-4 py-1 uppercase rounded-md',
                      {
                        'bg-green-500': status.paid() || status.partiallyPaid(),
                        'bg-blue-500': status.sent(),
                        'bg-yellow-500': status.pending(),
                        'bg-red-500': status.overdue(),
                        'bg-gray-500': status.voided(),
                      }
                    )}
                  >
                    <span>{strUnderscoreToSpace(invoice.status)}</span>
                  </div>
                  {userCan('invoices.history.view') && <RecentActivities />}
                </div>
              </h5>
              <div>
                <Actions />
                {status.pending() && userCan('invoices.queue.email') && (
                  <AutoOptionEmail />
                )}
                {(status.sent() || status.overdue()) &&
                  userCan('invoices.queue.collect') && <AutoOptionCollect />}
              </div>
              <div>
                <CommissionErrors />
                <PaymentsMade />
                <CreditsApplied />
              </div>

              <div className="sm:grid sm:grid-cols-2 sm:gap-3">
                <div className="self-start text-sm col-span-1 text-right sm:text-left mb-3 sm:mb-0">
                  <span className="text-gray-400">Bill To</span>

                  <p className="mt-3 mb-0 text-red-600">
                    <Link to={`/clients/zid/${invoice.customer_id}`}>
                      {invoice.customer_name}
                    </Link>
                  </p>
                  {invoice.billing_address && (
                    <>
                      <p className="mb-0 text-gray-900">
                        {invoice.billing_address.street}
                      </p>
                      <p className="mb-0 text-gray-900">
                        {invoice.billing_address.street2}
                      </p>
                      <p className="mb-0 text-gray-900">
                        {[
                          invoice.billing_address.city,
                          invoice.billing_address.state,
                          invoice.billing_address.zip,
                        ]
                          .filter(Boolean)
                          .join(', ')}
                      </p>
                      <p className="mb-0 text-gray-900">
                        {invoice.billing_address.country}
                      </p>
                    </>
                  )}
                </div>
                <div className="col-span-1 grid grid-cols-2 gap-2 text-sm text-left">
                  <div className="col-span-1 text-gray-400 flex justify-end">
                    <span className="w-1/2">Invoice Date</span>
                  </div>
                  <span className="col-span-1">
                    {dateFormatter(invoice.invoice_date)}
                  </span>
                  <div className="col-span-1 text-gray-400 flex justify-end">
                    <span className="w-1/2">Terms</span>
                  </div>
                  <span className="col-span-1">
                    {invoice.payment_terms_label}
                  </span>
                  <div className="col-span-1 text-gray-400 flex justify-end">
                    <span className="w-1/2">Due Date</span>
                  </div>
                  <span className="col-span-1">
                    {dateFormatter(invoice.due_date)}
                  </span>
                  <div className="col-span-1 text-gray-400 flex justify-end">
                    <span className="w-1/2">Reference #</span>
                  </div>
                  <span className="col-span-1">
                    {invoice.reference_id && invoice.reference_id != ''
                      ? invoice.reference_id
                      : invoice.reference_number}
                  </span>
                </div>
              </div>
              <Body />
              {isAgencySuperUser() && <SuperLogs />}
            </div>
          </div>
          <div className="client-updates container-fixed bg-gray-50 border-l border-gray-100">
            {userCan('invoices.updates.view') && <InvoiceUpdates />}
          </div>
        </div>
      ) : (
        <Loading />
      )}
    </div>
  );
};

export default Invoice;
