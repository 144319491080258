import React, { useState, useEffect } from 'react';
import useQueryParams from 'hooks/useQueryParams';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { Modal, Table } from 'components';
import { ago } from 'utils/formatters';
import { ChartPieIcon } from '@heroicons/react/solid';
import ModalHeader from 'components/ModalHeader';
import PieChart from './Charts/PieChart';

const LeadSourcesTable = ({ selectedDates }) => {
  const { sourcesPaginationParams } = useSelector;
  const { params, updateParams, sortParam } = useQueryParams({
    ...sourcesPaginationParams,
  });
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [source, setSource] = useState({});
  const [sourceStatus, setSourceStatus] = useState({});
  const [selectedId, setSelectedId] = useState(null);

  const getSource = async () => {
    setLoading(true);
    await axios
      .get(`agency/leads/source`, {
        params: {
          ...params,
          startDateStr: selectedDates.selectedStartDate,
          endDateStr: selectedDates.selectedEndDate,
        },
      })
      .then((res) => {
        setSource(res.data.data);
      });
    setLoading(false);
  };

  const getStatuses = async () => {
    setLoading(true);
    await axios.get(`agency/leads/source/${selectedId}`).then((res) => {
      setSourceStatus(res.data?.data?.leadsStatus);
    });
    setLoading(false);
  };

  //intial param
  useEffect(() => {
    updateParams({
      page: 1,
      pageSize: 10,
      sort: 'createdAt:desc',
    });
  }, []);

  useEffect(() => {
    async function getData() {
      await getSource();
      // await getStatuses();
    }
    getData();
  }, [params, selectedDates]);

  useEffect(() => {
    if (selectedId) {
      async function getData() {
        await getStatuses();
        // await getStatuses();
      }
      getData();
    }
  }, [selectedId]);

  const handleSelect = (id) => {
    setSelectedId(id);
    setOpen(true);
  };

  const tableColumns = [
    {
      dataField: 'uploadedByUser',
      text: 'Uploaded By',
      sort: true,
      headerStyle: {
        minWidth: '200px',
        whiteSpace: 'normal',
        backgroundColor: '#fff',
        position: 'sticky',
        left: 0,
        zIndex: 1,
      },
      style: {
        whiteSpace: 'normal',
        backgroundColor: '#fff',
        position: 'sticky',
        left: 0,
        zIndex: 1,
      },
      formatter: (cell, row) => {
        return (
          <span className="font-normal">
            {cell ? `${cell.firstName} ${cell.lastName}` : ''}
          </span>
        );
      },
    },
    {
      dataField: 'filename',
      text: 'File Name',
      sort: true,
      headerStyle: {
        minWidth: '180px',
      },
    },
    {
      dataField: 'totalRows',
      text: 'Total Rows',
      sort: true,
      headerStyle: {
        minWidth: '180px',
      },
    },
    {
      dataField: 'inserted',
      text: 'Inserted Rows',
      sort: true,
      headerStyle: {
        minWidth: '180px',
      },
    },
    {
      dataField: 'createdAt',
      text: 'Uploaded Date',
      sort: true,
      headerStyle: {
        minWidth: '180px',
      },
      formatter: (cell, row) => {
        return <span className="font-normal">{cell ? ago(cell) : ''}</span>;
      },
    },
    {
      dataField: 'leadSourceId',
      text: 'Data',
      sort: true,
      headerStyle: {
        minWidth: '180px',
      },
      formatter: (cell, row) => {
        return (
          <ChartPieIcon
            className="w-6 h-6 text-gray-500 hover:text-gray-900 cursor-pointer"
            onClick={() => handleSelect(cell)}
          />
        );
      },
    },
  ];

  const onTableChange = (type, { page, sizePerPage, sortField, sortOrder }) => {
    updateParams({
      page,
      pageSize: sizePerPage,
      sort: `${sortField ? sortField : 'createdAt'}:${sortOrder} nulls last`,
    });
  };

  return (
    <>
      {source.count > 0 ? (
        <Table
          columns={tableColumns}
          data={source}
          onTableChange={onTableChange}
          params={params}
          keyField="uploadUser"
          defaultSorted={[
            {
              dataField: sortParam ? sortParam[0] : 'createdAt',
              order: sortParam ? sortParam[1] : 'desc',
            },
          ]}
          loading={loading}
        />
      ) : (
        <div className="flex justify-center w-full py-6">
          <p>Nothing to display</p>
        </div>
      )}
      <Modal
        open={open}
        setOpen={setOpen}
        as={'div'}
        align="middle"
        noOverlayClick={true}
        persistent={true}
      >
        <div className="inline-block w-3/5 h-3/4 my-8 overflow-hidden text-left transition-all rounded-xl transform bg-white shadow-xl">
          <ModalHeader
            title={'Lead Source Metrics'}
            setOpen={setOpen}
            titleClasses="capitalize"
            border="border-0"
            px="px-8"
            py="py-4"
            fontSize="text-xl"
            fontStyle="font-bold"
            iconClassname="h-8 w-8"
            onClose={() => setOpen(false)}
          />
          <PieChart title="Report by status" data={sourceStatus} />
        </div>
      </Modal>
    </>
  );
};

export default LeadSourcesTable;
