import PageHeader from 'components/PageHeader';
import usePermissions from 'hooks/usePermissions';
import React from 'react';
import { Route, Switch } from 'react-router-dom';
import PitchTemplates from './components/PitchTemplates';

const LeadGeneration = ({ match }) => {
  const { path } = match;
  const { userCan } = usePermissions();
  const tabs = [
    {
      name: 'Pitch Templates',
      href: `/lead-generation/pitch-template`,
      exact: true,
      visible: userCan('leads.list'),
    },
  ];
  return (
    <>
      <PageHeader title="Lead Generation" containerClasses={''} border="" />
      <Switch>
        <Route
          path={`${path}/pitch-template`}
          render={(props) => <PitchTemplates tabs={tabs} {...props} />}
          exact
        />
        <Route
          path={`${path}/`}
          render={(props) => <PitchTemplates tabs={tabs} {...props} />}
          exact
        />
      </Switch>
    </>
  );
};
export default LeadGeneration;
