import axios from 'axios';
import filesize from 'filesize';
import ReactQuill, { Quill } from 'react-quill';
import Delta from 'quill-delta';
import 'react-quill/dist/quill.bubble.css';
import { MailIcon, ChatAltIcon, XIcon } from '@heroicons/react/solid';
import { useCallback, useEffect, useLayoutEffect, useState } from 'react';
import Button from 'components/Button';
import { useDispatch, useSelector } from 'react-redux';
import ButtonLink from 'components/ButtonLink';
import classNames from 'utils/classNames';
import React from 'react';
import { selectAuthenticatedUser } from 'features/auth/authSlice';
import { setAlert } from 'features/alerts/alertsSlice';
import Input from 'components/Forms/Input';
import { useFormik } from 'formik';
import { alertErrorsStringify } from 'utils/formatters';
import isEmail from 'validator/lib/isEmail';
import { capitalize, isEmpty, isEqual } from 'lodash';
import MailAttachment from 'components/Editor/MailAttachment';
import MailBlockButton from './components/MailBlockButton';
import useMail from './components/useMail';

const MailEditor = ({
  qRef,
  onChangeData,
  open,
  setOpen,
  editCommentValue = '',
  threadData,
  afterSend,
  setOpenEditor,
  isReplying = false,
  primaryKey,
  postUrl,
  isHasLinkedIn = false,
  canSwitchToComment = true,
  isExpandEmail = false,
  defaultTo = null,
  setOpenModal = null,
}) => {
  const [saving, setSaving] = useState(false);
  const dispatch = useDispatch();
  const user = useSelector(selectAuthenticatedUser);
  const [addCc, setAddCc] = useState(false);
  const [addBcc, setAddBcc] = useState(false);
  const [mailAttachments, setMailAttachments] = useState([]);
  const { defaultSignature, draftMail, mailSubject } = useSelector(
    (state) => state.mail
  );
  const [mailInitialValues, setMailInitialValues] = useState({
    mailTo: defaultTo ? defaultTo : '',
    mailSubject: mailSubject ? mailSubject : '',
    mailMessage: '',
    mailFrom: user.email,
    mailCc: '',
    mailBcc: '',
  });
  const {
    value,
    setValue,
    contentValue,
    setContentValue,
    formats,
    toolbarOptions,
    applyBlockToBody,
    imageHandler,
    saveDraftMail,
    saveMailSubject,
    pastedImageHandler,
  } = useMail(qRef);

  useEffect(() => {
    if (defaultSignature && !draftMail) {
      applyBlockToBody(defaultSignature, 'signature');
    }
  }, [defaultSignature, draftMail]);

  useEffect(() => {
    if (open && draftMail) {
      if (isEqual(primaryKey, draftMail.key)) {
        qRef.current.editor.setContents(draftMail.structure);
      } else saveDraftMail(null);
    }
  }, [open]);

  useEffect(() => {
    if (isReplying) {
      let mailCc;
      try {
        mailCc =
          threadData.mailCc &&
          threadData?.mailCc
            ?.match(/<([^>]+)>/g)
            .map((item) => item.replace(/[<>]/g, '').trim())
            .join(', ');
      } catch (error) {
        mailCc = threadData.mailCc;
      }

      setMailInitialValues({
        mailTo: threadData.mailTo,
        mailSubject: threadData.mailSubject,
        mailMessage: threadData.mailMessage,
        mailFrom: threadData.mailFrom,
        mailCc: threadData.mailCc ? mailCc : '',
        mailBcc: threadData.mailBcc,
      });
    }
  }, [threadData]);

  useEffect(() => {
    if (editCommentValue !== '') {
      setValue(editCommentValue);
    }
  }, [editCommentValue]);

  function addClassToImages(htmlString) {
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = htmlString;  
    const imgTags = tempDiv.querySelectorAll('img');
    imgTags.forEach((img) => {
      if (!img.classList.contains('inline-img')) {
        img.classList.add('inline-img');
      }
    });
    const modifiedHTMLString = tempDiv.innerHTML;
    return modifiedHTMLString;
  }

  const formik = useFormik({
    initialValues: mailInitialValues,
    enableReinitialize: true,
    validateOnChange: false, // this one
    validateOnBlur: false, // and this one
    validate: (values) => {
      const errors = {};

      if (!values.mailFrom.trim()) {
        errors.mailFrom = 'From is required';
      } else if (!isEmail(values.mailFrom.trim())) {
        errors.mailFrom = 'From is invalid';
      }

      if (!values.mailTo.trim()) {
        errors.mailTo = 'To is required';
      }

      if (!values.mailSubject.trim()) {
        errors.mailSubject = 'Subject is required';
      }

      if (isReplying) {
        if (!contentValue) {
          errors.mailMessage = 'Message body is required';
        }
      } else {
        if (!contentValue) {
          errors.mailMessage = 'Message body is required';
        }
      }

      if (!isEmpty(errors)) {
        dispatch(
          setAlert(
            'error',
            'Invalid email paramenters',
            alertErrorsStringify(errors)
          )
        );
      }

      return errors;
    },
    onSubmit: async (values) => {
      setSaving(true);
      try {
        const { mailCc, mailBcc, mailSubject, mailFrom } = values;
        let emailArray = values.mailTo.split(',');
        for (let i = 0; i < emailArray.length; i++) {
          const mailTo = emailArray[i].trim();
          if (mailAttachments.length > 0) {
            const formData = new FormData();

            mailAttachments.forEach((file) => {
              formData.append('attachment', file);
            });

            formData.append('mailTo', mailTo);
            formData.append('mailCc', mailCc);
            formData.append('mailBcc', mailBcc);
            formData.append('mailSubject', mailSubject);
            formData.append('mailFrom', mailFrom);

            formData.append(
              'structure',
              JSON.stringify(qRef.current.editor.getContents())
            );
            formData.append('text', qRef.current.editor.getText());

            //let message = contentValue;
            if (isReplying) {
              const { inReplyTo, references, threadId, mailMessage } =
                threadData;
              //message = `${contentValue}${mailMessage}`;
              formData.append('inReplyTo', inReplyTo);
              formData.append('references', references);
              formData.append('threadId', threadId);
              formData.append('quotedMessage', mailMessage);
            }
            formData.append(primaryKey.key, primaryKey.value);
            formData.append('mailMessage', contentValue);

            await axios.post(
              `${postUrl}${isReplying ? '/reply' : ''}/attachments`,
              formData
            );
          } else {
            let payload = {
              mailTo,
              mailCc,
              mailBcc,
              mailSubject,
              mailFrom,
              structure: qRef.current.editor.getContents(),
              text: qRef.current.editor.getText(),
            };

            if (isReplying) {
              const { inReplyTo, references, threadId, mailMessage } =
                threadData;
              payload.inReplyTo = inReplyTo;
              payload.references = references;
              payload.threadId = threadId;
              payload.quotedMessage = mailMessage;
            }
            payload[primaryKey.key] = primaryKey.value;
            payload.mailMessage = contentValue;

            await axios.post(
              `${postUrl}${isReplying ? '/reply' : ''}`,
              payload
            );
          }

          dispatch(
            setAlert(
              'success',
              'Email sent!',
              `Email was successfully sent to ${mailTo}`
            )
          );
        }
        setSaving(false);
        onChangeData(false);
        qRef.current.editor.setContents('');
        setMailAttachments([]);
        if (isReplying) {
          afterSend();
        } else if (isExpandEmail) {
          setOpenModal(false);
        } else {
          setTimeout(() => {
            setOpen(false);
          }, 500);
        }
      } catch (error) {
        dispatch(setAlert('error', 'Sending email failed!', error.message));
      }
      setSaving(false);
    },
  });

  const onAttached = (files) => {
    setMailAttachments(Array.from(files));
  };

  const onRemoveAttachment = (index) => {
    let ma = [...mailAttachments];
    ma.splice(index, 1);
    setMailAttachments(ma);
  };

  const onChangeContent = (content, delta, source, editor) => {
    setValue(editor.getContents());
    setContentValue(addClassToImages(content));

    saveDraftMail({
      structure: editor.getContents().ops,
      content: content,
      key: primaryKey,
    });
  };

  return (
    <div>
      <div>
        <div className="flex items-center px-4">
          <label className="text-sm text-gray-400">From:</label>
          <Input
            name="mailFrom"
            type="text"
            id="mailFrom"
            placeholder=""
            value={formik.values.mailFrom}
            onChange={formik.handleChange}
            rounded="rounded-none"
            border="border-0"
            classes="appearance-none pl-4 py-2 placeholder-gray-400 focus:outline-none focus:border-0 shadow-none focus:ring-0 hover:border-0"
            readOnly={isReplying ? true : false}
          />
          <ButtonLink
            onClick={() => setAddCc(!addCc)}
            color="gray"
            classes="pl-1 pr-0.5"
          >
            Cc
          </ButtonLink>
          <ButtonLink
            onClick={() => setAddBcc(!addBcc)}
            color="gray"
            classes="pl-0.5 pr-1"
          >
            Bcc
          </ButtonLink>
        </div>

        <div className="flex items-center border-b border-gray-200 px-4 flex-col">
          <div className="flex items-center w-full">
            <label className="text-sm text-gray-400 whitespace-nowrap">
              {isReplying ? 'Reply ' : ''}To:
            </label>
            <Input
              name="mailTo"
              type="text"
              id="mailTo"
              placeholder=""
              value={formik.values.mailTo}
              onChange={formik.handleChange}
              rounded="rounded-none"
              border="border-0 "
              classes="appearance-none pl-4 py-2 placeholder-gray-400 focus:outline-none focus:border-0 shadow-none focus:ring-0 hover:border-0"
              readOnly={isReplying ? true : false}
            />
          </div>
          {addCc && (
            <div className="flex items-center w-full">
              <label className="text-sm text-gray-400">Cc:</label>
              <Input
                name="mailCc"
                type="text"
                id="mailCc"
                placeholder=""
                value={formik.values.mailCc}
                onChange={formik.handleChange}
                rounded="rounded-none"
                border="border-0 "
                classes="appearance-none pl-4 py-2 placeholder-gray-400 focus:outline-none focus:border-0 shadow-none focus:ring-0 hover:border-0"
              />
            </div>
          )}
          {addBcc && (
            <div className="flex items-center w-full">
              <label className="text-sm text-gray-400">Bcc:</label>
              <Input
                name="mailBcc"
                type="text"
                id="mailBcc"
                placeholder=""
                value={formik.values.mailBcc}
                onChange={formik.handleChange}
                rounded="rounded-none"
                border="border-0 "
                classes="appearance-none pl-4 py-2 placeholder-gray-400 focus:outline-none focus:border-0 shadow-none focus:ring-0 hover:border-0"
              />
            </div>
          )}
        </div>

        <Input
          name="mailSubject"
          type="text"
          id="mailSubject"
          placeholder="Subject"
          value={mailSubject}
          onChange={(e) => {
            saveMailSubject(e.target.value);
            formik.handleChange(e);
          }}
          rounded="rounded-none"
          border="border-0 border-b border-gray-200"
          classes={classNames(
            isReplying ? 'hidden' : '',
            'appearance-none px-4 py-2 placeholder-gray-400 focus:outline-none focus:border-0 shadow-none focus:ring-0 hover:border-0'
          )}
          readOnly={isReplying ? true : false}
        />
      </div>

      <div
        className={classNames(
          isReplying && 'edit-comment',
          'quill-text-editor py-2 overflow-y-auto max-h-54vh'
        )}
      >
        <ReactQuill
          ref={qRef}
          theme="bubble"
          placeholder=""
          value={value}
          onChange={onChangeContent}
          bounds={`.quill-text-editor`}
          modules={{
            toolbar: {
              container: toolbarOptions,
              handlers: {
                image: imageHandler,
              },
            },
            clipboard: {
              matchVisual: false,
            },
            imageActions: {},
            imageFormats: {},
            imageDropAndPaste: { handler: pastedImageHandler },
          }}
          formats={formats}
        />
      </div>
      {mailAttachments.length > 0 && (
        <div className="flex flex-col items-start px-4 py-2 space-y-1">
          {mailAttachments.map((attachment, i) => (
            <div
              key={i}
              className="bg-gray-200 py-1 px-2 flex items-center text-sm"
            >
              <span className="">{attachment.name}</span>
              <span className="ml-1 mr-2">({filesize(attachment.size)})</span>
              <button
                className="hover:text-red-400 flex items-center font-inter"
                onClick={() => onRemoveAttachment(i)}
              >
                <XIcon className="w-3 h-3 inline" />
              </button>
            </div>
          ))}
        </div>
      )}

      <div
        className={classNames(
          isExpandEmail ? 'absolute w-full' : 'sticky',
          'flex justify-between py-3 px-4 border-t bottom-0 bg-white'
        )}
      >
        <div className="flex space-x-3 items-center">
          <MailAttachment onAttached={onAttached} title="Upload" />
          <MailBlockButton
            key="blockTemplate"
            type="template"
            applyBlockToBody={applyBlockToBody}
          />
          <MailBlockButton
            key="blockSignature"
            type="signature"
            applyBlockToBody={applyBlockToBody}
          />
        </div>
        <div className="space-x-4 flex items-center">
          {!isReplying && !isExpandEmail && (
            <div className="flex mx-1">
              {isHasLinkedIn ? (
                <ButtonLink
                  onClick={() => {
                    setOpenEditor('email');
                  }}
                  title="LinkedIn Thread"
                  classes="p-0.5 bg-blue-400 font-bold rounded"
                  color="white"
                >
                  in
                </ButtonLink>
              ) : (
                <ButtonLink
                  onClick={() => {
                    setOpenEditor('email');
                  }}
                  title="Send Email"
                  classes="p-0.5"
                >
                  <MailIcon className="w-5 h-5 inline text-gray-500 hover:text-blue-600" />
                </ButtonLink>
              )}
              <ButtonLink
                onClick={() => {
                  setOpenEditor('comment');
                }}
                title="Add Comment"
                classes="p-0.5"
                disabled={!canSwitchToComment}
              >
                <ChatAltIcon className="w-5 h-5 inline text-gray-500 hover:text-blue-600" />
              </ButtonLink>
            </div>
          )}
          {isReplying && (
            <ButtonLink
              classes="tracking-wider font-bold"
              color="red"
              onClick={() => {
                setOpen(false);
              }}
            >
              Cancel
            </ButtonLink>
          )}

          <Button
            roundedSize="2xl"
            classes="tracking-wider font-bold"
            px={6}
            py={2}
            textSize="xs"
            color="green"
            onClick={() => {
              formik.handleSubmit();
            }}
            showLoading={true}
            loading={saving}
            type="submit"
            form="formEmail"
          >
            {isReplying ? 'Reply' : 'Send'}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default MailEditor;
