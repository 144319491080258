import React, { useState } from 'react';
import CardHeader from '@material-ui/core/CardHeader';
import { PencilIcon } from '@heroicons/react/outline';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import IconButton from '@material-ui/core/IconButton';

const Actions = ({
  title,
  editPitchTemplateModal,
  deleteAction,
  id,
  deActivateAction,
  status,
}) => {
  const [showActions, setShowActions] = useState(false);

  const showActionHandler = () => {
    setShowActions(!showActions);
  };

  return (
    <CardHeader
      action={
        <div className="flex ">
          <IconButton
            aria-label="settings"
            onClick={() => editPitchTemplateModal(id)}
          >
            <PencilIcon className="m-1 h-5 w-5" />
          </IconButton>
          <div className="relative">
            <IconButton onClick={showActionHandler}>
              <MoreVertIcon />
            </IconButton>
            {showActions && (
              <div className="absolute top-10 right-1 ">
                <ul className="border p-2 rounded-xl w-28 bg-white z-10">
                  <li
                    className="mt-1 cursor-pointer"
                    onClick={() => deActivateAction(id, status)}
                  >
                    <div className="flex items-center text-left ">
                      <p className="ml-1 text-13">
                        {status === 'Deactivate' ? 'Activate' : 'Deactivate'}{' '}
                      </p>
                    </div>
                  </li>
                  <li
                    className="mt-1 cursor-pointer"
                    onClick={() => deleteAction(id)}
                  >
                    <div className="flex items-center text-left text-red-500">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-4 w-4"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        strokeWidth={2}
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                        />
                      </svg>
                      <p className="ml-1 text-13">Delete</p>
                    </div>
                  </li>
                </ul>
              </div>
            )}
          </div>
        </div>
      }
      className="ml-2 pt-0"
      titleTypographyProps={{ variant: 'p' }}
      title={title}
    />
  );
};

export default Actions;
