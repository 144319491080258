import AgencyLayout from 'layouts/agency/AgencyLayout';
import React from 'react';
import PrivateRoute from './PrivateRoute';
import Leads from 'features/leads/Leads';
import Marketing from 'features/leads/Marketing';
import Metrics from 'features/leads/Metrics';
import LeadsData from 'features/leads/LeadsData';
import LeadsSettings from 'features/leads/LeadsSettings';
import LeadsArchive from 'features/leads/LeadsArchive';
import LeadsNoSellerInfo from 'features/leads/LeadsNoSellerInfo';
import DeletedLeads from 'features/leads/DeletedLeads';
import LeadGeneration from 'features/leads/LeadGeneration';

const LeadRoutes = [
  <PrivateRoute
    path="/leads"
    component={Leads}
    layout={AgencyLayout}
    access={['agency']}
    permissions="leads.list"
  />,

  <PrivateRoute
    path="/leads-marketing-module"
    component={Marketing}
    layout={AgencyLayout}
    access={['agency']}
    permissions="leads.list"
  />,

  <PrivateRoute
    path="/leads-metrics/team/:podId/:teamName"
    component={Metrics}
    layout={AgencyLayout}
    access={['agency']}
    permissions="leads.list"
  />,

  <PrivateRoute
    path="/leads-metrics/:leadId/:leadName"
    component={Metrics}
    layout={AgencyLayout}
    access={['agency']}
    permissions="leads.list"
  />,

  <PrivateRoute
    path="/leads-metrics"
    component={Metrics}
    layout={AgencyLayout}
    access={['agency']}
    permissions="leads.list"
  />,

  <PrivateRoute
    path="/leads-archived"
    component={LeadsArchive}
    layout={AgencyLayout}
    access={['agency']}
    permissions="leads.list"
  />,

  <PrivateRoute
    path="/leads-data"
    component={LeadsData}
    layout={AgencyLayout}
    access={['agency']}
    permissions="leads.list"
  />,

  <PrivateRoute
    path="/leads-settings"
    component={LeadsSettings}
    layout={AgencyLayout}
    access={['agency']}
    permissions="leads.list"
  />,

  <PrivateRoute
    path="/leads-no-seller"
    component={LeadsNoSellerInfo}
    layout={AgencyLayout}
    access={['agency']}
    permissions="leads.list"
  />,

  <PrivateRoute
    path="/deleted-leads"
    component={DeletedLeads}
    layout={AgencyLayout}
    access={['agency']}
    permissions="leads.list"
  />,

  <PrivateRoute
    path="/lead-generation"
    component={LeadGeneration}
    layout={AgencyLayout}
    access={['agency']}
    permissions="leads.list"
  />,
];
export default LeadRoutes;
