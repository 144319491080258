import React, { useState } from 'react';
import Select from 'react-select';

const SelectFilter = ({ handleInsert }) => {
  const options = [
    {
      label: 'CONTACT DETAILS',
      options: [
        { label: 'First Name', value: 'First Name' },
        { label: 'Last Name', value: 'Last Name' },
        { label: 'Company Name', value: 'Company Name' },
        { label: 'Phone Number', value: 'Phone Number' },
        { label: 'Email', value: 'Email' },
        { label: 'Address', value: 'Address' },
        { label: 'Website', value: 'Website' },
        { label: 'Facebook URL', value: 'Facebook URL' },
        { label: 'Instagram URL', value: 'Instagram URL' },
      ],
    },
    {
      label: 'AMAZON/PRODUCT',
      options: [
        { label: 'Brand Name', value: 'Brand Name' },
        { label: 'Amazon Store Front URL', value: 'Amazon Store Front URL' },
        { label: 'Product Category', value: 'Product Category' },
        { label: 'Marketplace', value: 'Marketplace' },
        { label: 'Sub Category 1', value: 'Sub Category 1' },
        { label: 'Sub Category 2', value: 'Sub Category 2' },
        { label: 'ASIN Full Title', value: 'ASIN Full Title' },
        { label: 'ASIN URL', value: 'ASIN URL' },
        { label: 'ASIN', value: 'ASIN' },
        { label: 'ASIN Major Keyword', value: 'ASIN Major Keyword' },
        { label: 'Fullfilment', value: 'Fullfilment' },
        { label: 'ASIN Price', value: 'ASIN Price' },
        { label: 'ASIN Reviews', value: 'ASIN Reviews' },
        { label: 'ASIN Revenue', value: 'ASIN Revenue' },
        { label: 'Storefront Revenue', value: 'Storefront Revenue' },
      ],
    },
    {
      label: 'COMPETITOR',
      options: [
        { label: 'Competitor Brand Name', value: 'Competitor Brand Name' },
        { label: 'Comp ASIN URL', value: 'Comp ASIN URL' },
        { label: 'Competitor ASIN URL', value: 'Competitor ASIN URL' },
      ],
    },
  ];
  const [val, setVal] = useState('');
  const handleChange = (val) => {
    handleInsert(val);
    setVal('');
  };

  return (
    <div>
      <Select
        options={options}
        onChange={handleChange}
        value={val}
        placeholder="Insert Variables"
      />
    </div>
  );
};

export default SelectFilter;
